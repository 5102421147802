<template>
<div>
  <router-link :to="{ name: 'RanapMPP' }" class="text-dark">
    <i class="icon-arrow-left52 mr-1"></i>
    <span class="font-weight-semibold">Kembali</span>
  </router-link>

  <div class="card mt-2">
    <div class="card-header bg-white">
      <div class="row align-items-center">
        <div class="col-md-auto">
          <div class="row align-items-center">
            <div class="col-md-auto">
              <h5 class="card-title font-weight-semibold">Pemulangan (P3) MPP (Form C) </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <validation-observer ref="VFormEvaluasi">
      <b-form @submit.prevent="doSubmit()">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="wrap_width_table">
                <div class="table-responsive scroll_width">
                  <table class="table table-sm  table-bordered">
                    <thead>
                      <tr>
                        <th colspan="3">
                          <h5 class="mb-0 f_16">PERENCANAAN</h5>
                        </th>
                        <th colspan="2">
                          <h5 class="mb-0 f_16">PELAKSANAAN</h5>
                        </th>

                        <th rowspan="2">
                          <h5 class="mb-0 f_16">EVALUASI</h5>
                        </th>

                        <th style="width:200px" rowspan="2">
                          <h5 class="mb-0 f_16">NAMA PETUGAS</h5>
                        </th>

                      </tr>
                      <tr>
                        <th colspan="2">
                          <h5 class="mb-0 f_16">PROGRAM</h5>
                        </th>
                        <th style="min-width:200px">
                          <h5 class="mb-0 f_16">TGL</h5>
                        </th>
                        <th style="min-width:200px">
                          <h5 class="mb-0 f_16">TGL</h5>
                        </th>
                        <th>
                          <h5 class="mb-0 f_16">NAMA PETUGAS</h5>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(v, k) in row.arme_koordinasi_dpjp" :key="`dpjp-${k}`">
                        <td :rowspan="row.arme_koordinasi_dpjp.length" v-if="k === 0">Koordinasi dengan DPJP</td>
                        <td width="200">
                          {{ v.label }}
                        </td>
                        <td>
                          {{ v.start_date | moment("LLL") }}
                        </td>
                        <td>
                          {{ v.end_date | moment("LLL") }}
                        </td>

                        <td>
                          {{ v.name }}
                        </td>

                        <td>
                          <!-- <div class="input-group">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i
                                  class="icon-calendar"></i></span>
                            </div>                          

                            <datepicker
                              input-class="form-control transparent"
                              placeholder="Tanggal Evaluasi" class="my-datepicker"
                              calendar-class="my-datepicker_calendar"
                              v-model="v.evaluation_date"
                              :key="`dpjp_ev_date${k}`"
                            >
                            </datepicker>
                          </div> -->
                          <date-picker
                            v-model="v.evaluation_date" 
                            type="datetime"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm"                            
                            class="w-100"
                            placeholder="-- Tanggal Evaluasi --" 
                            @input="updateRow(row)"
                            :key="`dpdjp_ev_date${k}`"
                          >
                          </date-picker>
                          <VValidate
                            name="Tanggal Evaluasi"
                            v-model="v.evaluation_date"
                            :rules="mrValidation.evaluation_date"
                            :vid="`dpjp_ev_date${k}`"
                          />


                          <div class="mt-2">
                            <b-form-group>
                              <b-form-radio-group
                                @input="updateValueProgram($event, 'status', 'koordinasi_dpjp', k)"
                                @change="updateRow(row)"
                                :options="Config.mr.ranapStatusMppMampu"
                                :name="`koord_dpjp${k}`"
                                :id="`koord_dpjp${k}`"
                                :key="`koord_dpjp${k}`"
                                v-model="v.status"
                              ></b-form-radio-group>

                              <VValidate
                                name="Status"
                                v-model="v.status"
                                :rules="mrValidation.status"
                                :vid="`koord_dpjp${k}`"
                              />
                            </b-form-group>
                          </div>
                        </td>

                        <td>
                          <div>
                            <b-form-input placeholder="Nama" v-model="v.evaluator_name" @change="updateRow(row)"  />

                            <VValidate
                              name="Nama Petugas"
                              v-model="v.evaluator_name"
                              :rules="mrValidation.evaluator_name"
                              :vid="`name_dpjp${k}`"
                            />
                          </div>
                        </td>

                      </tr>
                    </tbody>
                    <tbody>
                      <tr v-for="(v, k) in row.arme_koordinasi_ppja" :key="`ppja-${k}`">
                        <td :rowspan="row.arme_koordinasi_ppja.length" v-if="k === 0">Koordinasi dengan PPJA (Perawat Penanggung Jawab Asuhan)</td>
                        <td width="200">
                          {{ v.label }}
                        </td>
                        <td>
                          {{ v.start_date | moment("LLL") }}
                        </td>
                        <td>
                          {{ v.end_date | moment("LLL") }}
                        </td>

                        <td>
                          {{ v.name }}
                        </td>

                        <td>
                          <!-- <div class="input-group">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i
                                  class="icon-calendar"></i></span>
                            </div>                          

                            <datepicker
                              input-class="form-control transparent"
                              placeholder="Tanggal Evaluasi" class="my-datepicker"
                              calendar-class="my-datepicker_calendar"
                              v-model="v.evaluation_date"
                              :key="`ppja_ev_date${k}`"
                            >
                            </datepicker>
                          </div> -->

                          <date-picker
                            v-model="v.evaluation_date" 
                            type="datetime"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm" 
                            class="w-100"
                            placeholder="-- Tanggal Evaluasi --" 
                            @input="updateRow(row)"
                            :key="`ppja_ev_date${k}`"
                          >
                          </date-picker>
                          <VValidate
                            name="Tanggal Evaluasi"
                            v-model="v.evaluation_date"
                            :rules="mrValidation.evaluation_date"
                            :vid="`ppja_ev_date${k}`"
                          />

                          <div class="mt-2">
                            <b-form-group>
                              <b-form-radio-group
                                @input="updateValueProgram($event, 'status', 'koordinasi_ppja', k)"
                                @change="updateRow(row)"
                                :options="Config.mr.ranapStatusMppMampu"
                                :name="`koord_ppja${k}`"
                                :id="`koord_ppja${k}`"
                                :key="`koord_ppja${k}`"
                                v-model="v.status"
                              ></b-form-radio-group>

                              <VValidate
                                name="Status"
                                v-model="v.status"
                                :rules="mrValidation.status"
                                :vid="`koord_ppja${k}`"
                              />
                            </b-form-group>
                          </div>
                        </td>

                        <td>
                          <div>
                            <b-form-input placeholder="Nama" v-model="v.evaluator_name" @change="updateRow(row)"  />

                            <VValidate
                              name="Nama Petugas"
                              v-model="v.evaluator_name"
                              :rules="mrValidation.evaluator_name"
                              :vid="`name_ppja${k}`"
                            />
                          </div>
                        </td>

                      </tr>

                    </tbody>
                    <tbody>
                      <tr v-for="(v, k) in row.arme_koordinasi_farmasi_klinis" :key="`farmasi-${k}`">
                        <td :rowspan="row.arme_koordinasi_farmasi_klinis.length" v-if="k === 0">Koordinasi dengan Farmasi Klinis</td>
                        <td width="200">
                          {{ v.label }}
                        </td>
                        <td>
                          {{ v.start_date | moment("LLL") }}
                        </td>
                        <td>
                          {{ v.end_date | moment("LLL") }}
                        </td>

                        <td>
                          {{ v.name }}
                        </td>

                        <td>
                          <!-- <div class="input-group">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i
                                  class="icon-calendar"></i></span>
                            </div>                          

                            <datepicker
                              input-class="form-control transparent"
                              placeholder="Tanggal Evaluasi" class="my-datepicker"
                              calendar-class="my-datepicker_calendar"
                              v-model="v.evaluation_date"
                              :key="`farmasi_ev_date${k}`"
                            >
                            </datepicker>
                          </div> -->

                          <date-picker
                            v-model="v.evaluation_date" 
                            type="datetime"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm" 
                            class="w-100"
                            placeholder="-- Tanggal Evaluasi --" 
                            @input="updateRow(row)"
                            :key="`farmasi_ev_date${k}`"
                          >
                          </date-picker>

                          <VValidate
                            name="Tanggal Evaluasi"
                            v-model="v.evaluation_date"
                            :rules="mrValidation.evaluation_date"
                            :vid="`farmasi_ev_date${k}`"
                          />

                          <div class="mt-2">
                            <b-form-group>
                              <b-form-radio-group
                                @input="updateValueProgram($event, 'status', 'koordinasi_farmasi', k)"
                                @change="updateRow(row)"
                                :options="Config.mr.ranapStatusMppMampu"
                                :name="`koord_farmasi${k}`"
                                :id="`koord_farmasi${k}`"
                                :key="`koord_farmasi${k}`"
                                v-model="v.status"
                              ></b-form-radio-group>

                              <VValidate
                                name="Status"
                                v-model="v.status"
                                :rules="mrValidation.status"
                                :vid="`koord_farmasi${k}`"
                              />
                            </b-form-group>
                          </div>
                        </td>

                        <td>
                          <div>
                            <b-form-input placeholder="Nama" v-model="v.evaluator_name" @change="updateRow(row)"  />

                            <VValidate
                              name="Nama Petugas"
                              v-model="v.evaluator_name"
                              :rules="mrValidation.evaluator_name"
                              :vid="`name_farmasi${k}`"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>

                    <tbody>
                      <tr v-for="(v, k) in row.arme_koordinasi_nutrisions" :key="`nutrisions-${k}`">
                        <td :rowspan="row.arme_koordinasi_nutrisions.length" v-if="k === 0">Koordinasi dengan Nutrions/ahli gizi</td>
                        <td width="200">
                          {{ v.label }}
                        </td>
                        <td>
                          {{ v.start_date | moment("LLL") }}
                        </td>
                        <td>
                          {{ v.end_date | moment("LLL") }}
                        </td>

                        <td>
                          {{ v.name }}
                        </td>

                        <td>
                          <!-- <div class="input-group">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i
                                  class="icon-calendar"></i></span>
                            </div>                          

                            <datepicker
                              input-class="form-control transparent"
                              placeholder="Tanggal Evaluasi" class="my-datepicker"
                              calendar-class="my-datepicker_calendar"
                              v-model="v.evaluation_date"
                              :key="`nutrisions_ev_date${k}`"
                            >
                            </datepicker>
                          </div> -->

                          <date-picker
                            v-model="v.evaluation_date" 
                            type="datetime"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm" 
                            class="w-100"
                            placeholder="-- Tanggal Evaluasi --" 
                            @input="updateRow(row)"
                            :key="`nutrisions_ev_date${k}`"
                          >
                          </date-picker>

                          <VValidate
                            name="Tanggal Evaluasi"
                            v-model="v.evaluation_date"
                            :rules="mrValidation.evaluation_date"
                            :vid="`nutrisions_ev_date${k}`"
                          />

                          <div class="mt-2">
                            <b-form-group>
                              <b-form-radio-group
                                @input="updateValueProgram($event, 'status', 'koordinasi_nutrisions', k)"
                                @change="updateRow(row)"
                                :options="Config.mr.ranapStatusMppMampu"
                                :name="`koord_nutrisions${k}`"
                                :id="`koord_nutrisions${k}`"
                                :key="`koord_nutrisions${k}`"
                                v-model="v.status"
                              ></b-form-radio-group>

                              <VValidate
                                name="Status"
                                v-model="v.status"
                                :rules="mrValidation.status"
                                :vid="`koord_nutrisions${k}`"
                              />
                            </b-form-group>
                          </div>
                        </td>

                        <td>
                          <div>
                            <b-form-input placeholder="Nama" v-model="v.evaluator_name" @change="updateRow(row)"  />

                            <VValidate
                              name="Nama Petugas"
                              v-model="v.evaluator_name"
                              :rules="mrValidation.evaluator_name"
                              :vid="`name_nutrisions${k}`"
                            />
                          </div>
                        </td>

                      </tr>
                    </tbody>
                    <tbody>
                      <tr v-for="(v, k) in row.arme_koordinasi_rehabilitasi" :key="`rehabilitasi-${k}`">
                        <td :rowspan="row.arme_koordinasi_rehabilitasi.length" v-if="k === 0">Koordinasi dengan rehabilitasi</td>
                        <td width="200">
                          {{ v.label }}
                        </td>
                        <td>
                          {{ v.start_date | moment("LLL") }}
                        </td>
                        <td>
                          {{ v.end_date | moment("LLL") }}
                        </td>

                        <td>
                          {{ v.name }}
                        </td>

                        <td>
                          <!-- <div class="input-group">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i
                                  class="icon-calendar"></i></span>
                            </div>                          

                            <datepicker
                              input-class="form-control transparent"
                              placeholder="Tanggal Evaluasi" class="my-datepicker"
                              calendar-class="my-datepicker_calendar"
                              v-model="v.evaluation_date"
                              :key="`rehabilitasi_ev_date${k}`"
                            >
                            </datepicker>
                          </div> -->

                          <date-picker
                            v-model="v.evaluation_date" 
                            type="datetime"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm" 
                            class="w-100"
                            placeholder="-- Tanggal Evaluasi --" 
                            @input="updateRow(row)"
                            :key="`rehabilitasi_ev_date${k}`"
                          >
                          </date-picker>

                          <VValidate
                            name="Tanggal Evaluasi"
                            v-model="v.evaluation_date"
                            :rules="mrValidation.evaluation_date"
                            :vid="`rehabilitasi_ev_date${k}`"
                          />

                          <div class="mt-2">
                            <b-form-group>
                              <b-form-radio-group
                                @input="updateValueProgram($event, 'status', 'koordinasi_rehabilitasi', k)"
                                @change="updateRow(row)"
                                :options="Config.mr.ranapStatusMppMampu"
                                :name="`koord_rehabilitasi${k}`"
                                :id="`koord_rehabilitasi${k}`"
                                :key="`koord_rehabilitasi${k}`"
                                v-model="v.status"
                              ></b-form-radio-group>

                              <VValidate
                                name="Status"
                                v-model="v.status"
                                :rules="mrValidation.status"
                                :vid="`koord_rehabilitasi${k}`"
                              />
                            </b-form-group>
                          </div>
                        </td>

                        <td>
                          <div>
                            <b-form-input placeholder="Nama" v-model="v.evaluator_name" @change="updateRow(row)"  />

                            <VValidate
                              name="Nama Petugas"
                              v-model="v.evaluator_name"
                              :rules="mrValidation.evaluator_name"
                              :vid="`name_rehabilitasi${k}`"
                            />
                          </div>
                        </td>

                      </tr>
                    </tbody>
                    <tbody>
                      <tr v-for="(v, k) in row.arme_koordinasi_petugas" :key="`petugas-${k}`">
                        <td :rowspan="row.arme_koordinasi_petugas.length" v-if="k === 0">Koordinasi dengan Petugas</td>
                        <td width="200">
                          {{ v.label }}
                        </td>
                        <td>
                          {{ v.start_date | moment("LLL") }}
                        </td>
                        <td>
                          {{ v.end_date | moment("LLL") }}
                        </td>

                        <td>
                          {{ v.name }}
                        </td>

                        <td>
                          <!-- <div class="input-group">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i
                                  class="icon-calendar"></i></span>
                            </div>                          

                            <datepicker
                              input-class="form-control transparent"
                              placeholder="Tanggal Evaluasi" class="my-datepicker"
                              calendar-class="my-datepicker_calendar"
                              v-model="v.evaluation_date"
                              :key="`petugas_ev_date${k}`"
                            >
                            </datepicker>
                          </div> -->

                          <date-picker
                            v-model="v.evaluation_date" 
                            type="datetime"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm" 
                            class="w-100"
                            placeholder="-- Tanggal Evaluasi --" 
                            @input="updateRow(row)"
                            :key="`petugas_ev_date${k}`"
                          >
                          </date-picker>

                          <VValidate
                            name="Tanggal Evaluasi"
                            v-model="v.evaluation_date"
                            :rules="mrValidation.evaluation_date"
                            :vid="`petugas_ev_date${k}`"
                          />

                          <div class="mt-2">
                            <b-form-group>
                              <b-form-radio-group
                                @input="updateValueProgram($event, 'status', 'koordinasi_petugas', k)"
                                @change="updateRow(row)"
                                :options="Config.mr.ranapStatusMppMampu"
                                :name="`koord_petugas${k}`"
                                :id="`koord_petugas${k}`"
                                :key="`koord_petugas${k}`"
                                v-model="v.status"
                              ></b-form-radio-group>

                              <VValidate
                                name="Status"
                                v-model="v.status"
                                :rules="mrValidation.status"
                                :vid="`koord_petugas${k}`"
                              />
                            </b-form-group>
                          </div>
                        </td>

                        <td>
                          <div>
                            <b-form-input placeholder="Nama" v-model="v.evaluator_name" @change="updateRow(row)"  />

                            <VValidate
                              name="Nama Petugas"
                              v-model="v.evaluator_name"
                              :rules="mrValidation.evaluator_name"
                              :vid="`name_petugas${k}`"
                            />
                          </div>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="text-right">
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</div>
</template>

<script>
import MonitoringOptions from '@/libs/MonitoringOptions.js'
import Formatter from '@/libs/Formatter.js'
import Gen from '@/libs/Gen'
import Config from '@/libs/Config.js'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  name: "FormData",
  components: {
    DatePicker
  },

  data() {
    return {
      formData: {
        koordinasi_dpjp: [],
        koordinasi_ppja: [],
        koordinasi_farmasi: [],
        koordinasi_nutrisions: [],
        koordinasi_rehabilitasi: [],
        koordinasi_petugas: [],
      },
      dumpProperty: {
        name: null,
        start_date: null,
        end_date: null,
      }
    }
  },

  methods: {
    updateRow(data){
      Gen.apiRest(
        "/do/" + this.$parent.modulePage, {
          data: {
            type: "update-evaluasi",
            id_registrasi: this.$route.params.pageSlug,            
            ...data
          }
        },
        "POST"
      )
    },
    openHistory() {
      this.$bvModal.show("historyData")

      this.$parent.getHistoryData(1)
    },

    doSubmit() {
      this.$refs['VFormEvaluasi'].validate().then(success => {
        if (!success) return false

        this.$swal.fire({
          icon: "warning",
          title: "<h2 class='font-weight-bold'>Sudah Yakin Dengan Data yang Diisi?</h2>",
          html: `
            <span style="font-size: 16px">
              Pastikan untuk mengisikan data yang sesuai, karena data yang sudah disubmit sudah tidak dapat diubah
            </span>              
          `,

          showCancelButton: true,
          cancelButtonText: "Cek Ulang",
          confirmButtonText: "Lanjutkan & Submit"
        }).then(button => {
          if (button.isConfirmed) {
            this.$parent.doSubmitEvaluasi({
              ...this.row,
              is_submit: true,
              type: 'update-evaluasi'
            })
          }
        })
      })
    }
  },

  computed: {
    optionsEWS() {
      return MonitoringOptions.EWS
    },
    MonitoringOptions() {
      return MonitoringOptions
    },

    Formatter() {
      return Formatter
    },

    Config() {
      return Config
    },

    historyData() {
      return this.$parent.tHistoryData
    },

    mrValidation() {
      return this.$parent.mrValidation
    },

    row(){
      return this.$parent.row
    }

  },
}
</script>
