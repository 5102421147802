<template>
<div class="content-wrapper">
  <div v-if="isList" class="content pt-0">
    <bo-page-title />
    <div class="card">
      <div class="card-header bg-white">
        <div class="row align-items-center">
          <div class="col-md-8">
            <div class="form-row align-items-center">
              <div class="col-md-1">
                <h5 class="card-title font-weight-semibold">MPP</h5>
              </div>

              <div class="col-md-7">
                <div class="form-group mb-0">
                  <div class="input-group">
                    <date-range-picker ref="picker" :locale-data="datePickerConfig.locale" :autoApply="datePickerConfig.autoApply" v-model="dateRange" :opens="'right'" :ranges="datePickerConfig.ranges" @update="updateValues">
                      <template v-slot:input="picker">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      </template>
                    </date-range-picker>
                    <div class="input-group-append calendar-group">
                      <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-header">
        <div class="row">
          <div class="col-lg-9">
            <div class="row gx-1">
              <div class="col-md">
                <div class="row gx-1">
                  <div class="col-md-auto">
                    <div class="form-group mb-0">
                      <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right><i class="icon-spinner11"></i></button>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <b-form-group class="mb-0">
                      <v-select placeholder=" -- Pilih DPJP -- " @input="doFill" v-model="filter.dpjp" :options="mDPJP" label="text" :reduce="v=>v.value"></v-select>
                    </b-form-group>
                  </div>

                  <div class="col-md-3">
                    <b-form-group class="mb-0">
                      <v-select placeholder=" -- Pilih Ruangan -- " @input="doFill" v-model="filter.ranap_bangsal" :options="mRanapBangsal" label="text" :reduce="v=>v.value"></v-select>
                    </b-form-group>
                  </div>

                  <div class="col-md-3">
                    <b-form-group class="mb-0">
                      <v-select placeholder=" -- Pilih Cara Masuk -- " @input="doFill" v-model="filter.cara_masuk" :options="Config.mr.caraMasuk" label="text" :reduce="v=>v.value"></v-select>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
              <input class="form-control" v-model="filter.search" @input="doSearch" placeholder="Ketik Nama/No. RM Pasien" />
              <div class="form-control-feedback">
                <i class="icon-search4 text-indigo"></i>
              </div>

              <b-button class="ml-1 d-inline-flex align-items-center" variant="outline-success" id="resetBtn" @click="doResetData()">Reset</b-button>
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
          <thead>
            <tr>
              <th>No. Kedatangan</th>
              <th>Nama/No KTP</th>
              <th>Dokter DPJP</th>
              <th>No RM</th>
              <th>Ruangan</th>
              <th>Tanggal lahir</th>
              <th>Usia</th>
              <th>PPJP</th>
              <th>Status</th>
              <th width="120">Aksi</th>
            </tr>
          </thead>

          <tbody v-if="(dataList||[]).length">
            <template v-for="(v,k) in (dataList||[])">
              <tr :key="k">
                <td><span class="font-weight-semibold">{{ v.aranr_reg_code }}</span><br><span class="text-primary">PASIEN BARU</span>
                </td>
                <td>
                  <div class="d-flex flex-column align-items-start">
                    <a href="javascript:;" @click="toMonitoring(v.aranr_id)" class="font-weight-semibold border-bottom" v-b-tooltip.hover.right title="Lihat Informasi Perawatan Pasien">{{ v.ap_fullname || "-" }}</a>
                    <small>{{ v.ap_code || "-" }}</small>
                  </div>
                  <small class="text-secondary">Terdaftar {{v.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
                </td>

                <td>{{ v.bu_full_name }}</td>
                <td>
                  <div class="font-weight-semibold">{{ v.ap_code || "-" }}</div>
                </td>
                <td>
                  <div class="font-weight-semibold">{{v.mrank_name||"-"}}</div>
                  <small><span>{{v.mranb_name||"-"}}</span> - <span>{{v.mrankel_name||"-"}}</span></small>
                </td>
                <td>
                  <div class="font-weight-semibold">{{ v.ap_dob | moment("DD MMMM YYYY") }}</div>
                </td>
                <td> {{v.ap_usia_with_ket || "-"}} <span class="text-success font-weight-semibold">{{ v.ap_gol_usia }}</span></td>
                <td>{{ v.nama_ppjp || "-" }}</td>
                <td>
                  <span class="badge bg-warning mb-1" v-if="v.ardpc_status == 'KAJIAN_MPP'">
                    Kajian MPP
                  </span>
                  <span class="badge bg-info mb-1" v-if="v.ardpc_status == 'DALAM_KAJIAN'">
                    Dalam Kajian
                  </span>
                  <span class="badge bg-teal mb-1" v-else-if="v.ardpc_status == 'SELESAI'">
                    Selesai
                  </span>

                  <span class="badge bg-warning mb-1" v-if="v.ardpc_is_draft == 'Y'">
                    Draft
                  </span>
                </td>
                <td>
                  <a href="javascript:;" v-b-tooltip.hover.right title="Lihat Detail" class="btn btn-icon rounded-round btn-sm alpha-info border-info" @click="openDetail(v)" v-if="moduleRole('view')">
                    <i class="icon-file-eye"></i>
                  </a>

                  <template v-if="moduleRole('kajian_awal') && v.ardpc_status == 'KAJIAN_MPP'">
                    <router-link :to="{ name: 'RanapMPP', params: { pageSlug: v.aranr_id }, query: { viewType: 'KAJIAN_AWAL' } }" class="btn btn-icon rounded-round  btn-sm alpha-success border-success text-success-800" v-b-tooltip.hover.right title="MPP Kajian Awal">
                      <i class="fa-solid fa-notes-medical"></i>
                    </router-link>
                  </template>

                  <template v-if="v.ardpc_status == 'DALAM_KAJIAN'">
                    <router-link :to="{ name: 'RanapMPP', params: { pageSlug: v.aranr_id }, query: { viewType: 'IMPLEMENTASI' } }" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" v-b-tooltip.hover.right title="MPP Form Implementasi" v-if="moduleRole('form_implementasi')">
                      <i class="fa-solid fa-notes-medical"></i>
                    </router-link>

                    <router-link :to="{ name: 'RanapMPP', params: { pageSlug: v.aranr_id }, query: { viewType: 'PERENCANAAN' } }" class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800" v-b-tooltip.hover.right title="MPP Form Perencanaan" v-if="moduleRole('form_perencanaan') && !v.show_evaluation_action">
                      <i class="fa-solid fa-notes-medical"></i>
                    </router-link>

                    <router-link :to="{ name: 'RanapMPP', params: { pageSlug: v.aranr_id }, query: { viewType: 'EVALUASI' } }" class="btn btn-icon rounded-round btn-sm alpha-purple border-purple text-purple-800" v-b-tooltip.hover.right title="MPP Form Evaluasi" v-if="moduleRole('form_evaluasi') && v.show_evaluation_action">
                      <i class="fa-solid fa-notes-medical"></i>
                    </router-link>
                  </template>

                </td>
              </tr>
            </template>

          </tbody>
          <tbody v-if="data.data.length == 0">

            <tr>
              <td colspan="99">
                <div class="text-center">
                  <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                    <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!data.data">
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
          </tbody>
        </table>

        <div :class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
          <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
            <i class="icon-chevron-left"></i>
          </a>
          <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
            <i class="icon-chevron-right"></i>
          </a>
        </div>
      </div>

      <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">
        <b-pagination class="mb-0" v-model="pageNo" :per-page="data.per_page" :total-rows="data.total" />
      </b-card-footer>

    </div>

    <b-modal id="detailData" size="xl" title="Detail MPP" hide-footer>
      <b-tabs nav-class="nav-tabs-bottom nav-justified mt-2 mb-0">
        <b-tab title="Kajian Awal">
          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Identifikasi / Skrining Pasien</h6>
            </div>
            <div class="card-body p-3">
              <div class="row g-1">
                <div class="col-md-4" v-for="(v, k) in dataKajianAwal.armka_screening" :key="`screening-${k}`">
                  <div class="det-check-item">
                    <i class="icon-checkmark-circle text-success"></i>
                    <span>{{ v.label }} {{ v.note ? ` - ${v.note}` : `` }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Asesmen Manajemen Pelayanan Pasien</h6>
            </div>
            <table class="table table-bordered table-sm">
              <tr>
                <td class="align-top" width="33%">
                  <div class="result_tab">
                    <h4>Penurunan kemampuan fungsional dan kemandirian pasien dalam pemenuhan ADL’s</h4>
                    <p v-if="dataKajianAwal.armka_fullfillment_adls_status == 'HAMBATAN'">
                      Hambatan {{ dataKajianAwal.armka_fullfillment_adls_note ? `- ${dataKajianAwal.armka_fullfillment_adls_note}` : '' }}</p>
                    <p v-else>Tidak ada</p>
                  </div>
                </td>
                <td class="align-top" width="33%">
                  <div class="result_tab">
                    <h4>Riwayat kesehatan dan/atau kebiasaan</h4>
                    <ul class="mb-0 pl-3" v-if="dataKajianAwal.armka_medical_history_status == 'ADA'">
                      <li v-for="(v, k) in dataKajianAwal.armka_medical_history_value" :key="`bs-${k}`">
                        {{ v.label }} {{ v.note ? `- ${v.note}` : `` }}
                      </li>
                    </ul>
                    <p v-else>Tidak Ada</p>
                  </div>
                </td>
                <td class="align-top" width="33%">
                  <div class="result_tab">
                    <h4>Perilaku spiritual, sosial, dan kultural</h4>
                    <ul class="mb-0 pl-3" v-if="dataKajianAwal.armka_behavior_spiritual_status == 'HAMBATAN'">
                      <li v-for="(v, k) in dataKajianAwal.armka_behavior_spiritual_value" :key="`bs-${k}`">
                        {{ v.label }} {{ v.note ? `- ${v.note}` : `` }}
                      </li>
                    </ul>

                    <p v-else>Tidak ada hambatan</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="result_tab">
                    <h4>Lingkungan dan Tempat Tinggal</h4>
                    <div class="mt-1" v-if="dataKajianAwal.armka_environment_status == 'HAMBATAN'">
                      <div class="det-check-item" v-for="(v, k) in dataKajianAwal.armka_environment_value" :key="`env-${k}`">
                        <i class="icon-checkmark-circle text-success"></i><span>{{ v.label }} {{ v.note ? ` - ${v.note}` : `` }}</span>
                      </div>
                    </div>
                    <p v-else>Tidak ada hambatan</p>
                  </div>
                </td>
                <td class="align-top">
                  <div class="result_tab">
                    <h4>Tersedia dukungan keluarga dan kemampuan untuk melanjutkan perawatan</h4>
                    <div class="mt-1" v-if="dataKajianAwal.armka_family_support_status == 'HAMBATAN'">
                      <div class="det-check-item" v-for="(v, k) in dataKajianAwal.armka_family_support_value" :key="`env-${k}`">
                        <i class="icon-checkmark-circle text-success"></i><span>{{ v.label }} {{ v.note ? ` - ${v.note}` : `` }}</span>
                      </div>
                    </div>
                    <p v-else>Tidak ada hambatan</p>
                  </div>
                </td>
                <td class="align-top">
                  <div class="result_tab">
                    <h4>Kemampuan finansial</h4>
                    <div class="mt-1" v-if="dataKajianAwal.armka_financial_status == 'HAMBATAN'">
                      <div class="det-check-item" v-for="(v, k) in dataKajianAwal.armka_financial_value" :key="`env-${k}`">
                        <i class="icon-checkmark-circle text-success"></i><span>{{ v.label }} {{ v.note ? ` - ${v.note}` : `` }}</span>
                      </div>
                    </div>
                    <p v-else>Tidak ada hambatan</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="result_tab">
                    <h4>Status Asuransi</h4>
                    <p v-if="dataKajianAwal.armka_insurance_status == 'TIDAK_AKTIF'">
                      Tidak Aktif;
                      <ul class="mb-0 pl-3 mt-2">
                        <li v-for="(v, k) in dataKajianAwal.armka_insurance_value" :key="`insurances-${k}`">
                          {{ v.label.replaceKeterangan() }} {{ v.note ? `- ${v.note}` : `` }}
                        </li>
                      </ul>
                    </p>
                    <p v-else>Aktif</p>
                  </div>
                </td>
                <td class="align-top">
                  <div class="result_tab">
                    <h4>Riwayat pengobatan alternatif dan/atau Non Medis</h4>
                    <p v-if="dataKajianAwal.armka_history_alternative == 'ADA'">
                      Ada {{ dataKajianAwal.armka_history_alternative_note ? `- ${dataKajianAwal.armka_history_alternative_note}` : '' }}</p>
                    <p v-else>Tidak Ada</p>
                  </div>
                </td>
                <td class="align-top">
                  <div class="result_tab">
                    <h4>Pemahaman terhadap kesehatan</h4>
                    <p v-if="dataKajianAwal.armka_health_understanding == 'KURANG'">
                      Kurang, {{ dataKajianAwal.armka_health_understanding_note }}
                    </p>
                    <p v-else>Baik</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="result_tab">
                    <h4>Harapan terhadap asuhan</h4>
                    <p>
                      {{ dataKajianAwal.armka_expectations_of_care }}
                      {{ dataKajianAwal.armka_expectations_of_care_note ? ` - ${dataKajianAwal.armka_expectations_of_care_note}` : `` }}
                    </p>
                  </div>
                </td>
                <td class="align-top">
                  <div class="result_tab">
                    <h4>Discharge planning</h4>
                    <p>
                      {{ dataKajianAwal.armka_discharge_planning }}
                      {{ dataKajianAwal.armka_discharge_planning_note ? ` - ${dataKajianAwal.armka_discharge_planning_note}` : `` }}
                    </p>
                  </div>
                </td>
                <td class="align-top">
                  <div class="result_tab">
                    <h4>Aspek legal/Advokasi</h4>
                    <p>
                      {{ dataKajianAwal.armka_advocate }}
                      {{ dataKajianAwal.armka_advocate_note ? ` - ${dataKajianAwal.armka_advocate_note}` : `` }}
                    </p>
                  </div>
                </td>
              </tr>
            </table>
          </div>

          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Identifikasi Masalah dan Kesempatan</h6>
            </div>
            <div class="card-body p-3">
              <div class="row g-1">
                <div class="col-md-4" v-for="(v, k) in dataKajianAwal.armka_problem_identification" :key="`pi-${k}`">
                  <div class="det-check-item">
                    <i class="icon-checkmark-circle text-success"></i>
                    <span>{{ v.label }} {{ v.note ? ` - ${v.note}` : `` }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Perencanaan MPP</h6>
            </div>
            <div class="card-body p-3">
              <div class="row g-1">
                <div class="col-md-4" v-for="(v, k) in dataKajianAwal.armka_mpp_planning" :key="`mppp-${k}`">
                  <div class="det-check-item">
                    <i class="icon-checkmark-circle text-success"></i>
                    <span>{{ v.label }} {{ v.note ? ` - ${v.note}` : `` }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Keputusan Keluarga</h6>
            </div>
            <div class="card-body p-3">
              <div class="row g-1">
                <div class="col-md-4" v-for="(v, k) in dataKajianAwal.armka_screening" :key="`screening-${k}`">
                  <div class="det-check-item">
                    <i class="icon-checkmark-circle text-success"></i>
                    <span>{{ v.label }} {{ v.note ? ` - ${v.note}` : `` }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Kesimpulan</h6>
            </div>
            <table class="table table-sm table-bordered">
              <tr>
                <td width="40%">
                  <div class="result_tab">
                    <h4>Pasien ini perlu dikelola MPP?</h4>
                    <p>{{ dataKajianAwal.armka_summary }}</p>
                  </div>
                </td>
                <td width="60%">
                  <div class="result_tab">
                    <h4>Catatan MPP</h4>
                    <p>{{ dataKajianAwal.armka_mpp_note || '-' }}</p>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </b-tab>
        <b-tab title="Implementasi">
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th class="bg-success text-center">
                  <h5 class="mb-0 f_16">PELAKSANAAN</h5>
                  <p class="mb-0">Tanggal dan Jam</p>
                </th>
                <th class="bg-primary text-center">
                  <h5 class="mb-0 f_16">CATATAN IMPLEMENTASI</h5>
                  <p class="mb-0">Diisi dalam format IAR
                    Dilanjutkan dengan catatan monitoring dan implementasi oleh MPP sampai dengan terminasi
                  </p>
                </th>
                <th class="bg-primary text-center">
                  <h5 class="mb-0 f_16">NAMA PETUGAS</h5>
                </th>
                <th class="bg-primary text-center">
                  <h5 class="mb-0 f_16">ACTION</h5>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v, k) in dataImplementasi" :key="`imp-${k}`">
                <td>{{ v.armim_date | moment("LLL") }}</td>
                <td><span v-html="v.armim_note"></span></td>
                <td>{{ v.armim_bright_name }}</td>
                <td>
                  <a href="javascript:;" class="btn alpha-success border-success text-success-800 btn-icon rounded-round" data-popup="tooltip" v-b-tooltip.hover.left title="Edit" @click="editImplementasi(v)">
                    <i class="icon-pencil7"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </b-tab>
        <b-tab title="Perencanaan & Evaluasi">
          <div class="table-responsive">
            <table class="table table-bordered table-sm">
              <thead>
                <tr>
                  <th>Tanggal Perencanaan Dibuat</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v, k) in dataPerencanaanEvaluasi" :key="`peva-${k}`">
                  <td>{{ v.armp_created_date | moment("LLL") }}</td>
                  <td>
                    <b-badge variant="danger" v-if="v.armp_has_evaluated == 'N'">Belum Dievaluasi</b-badge>
                    <b-badge variant="success" v-else>Sudah Dievaluasi</b-badge>
                  </td>
                  <td>
                    <a href="javascript:;" class="btn alpha-success border-success text-success-800 btn-icon rounded-round" data-popup="tooltip" v-b-tooltip.hover.left title="Edit" @click="editPerencanaanEvaluasi(v)">
                      <i class="icon-pencil7"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>

    <b-modal id="formImplementasi" size="xl" title="Edit Implementasi" hide-footer>

      <validation-observer ref="VFormImplementasi">
        <b-form @submit.prevent="doSubmitModal('VFormImplementasi')">
          <div class="row">
            <div class="col-md-12">
              <table class="table table-sm  table-bordered">
                <thead>
                  <tr>
                    <th class="bg-success text-center">
                      <h5 class="mb-0 f_16">PELAKSANAAN</h5>
                      <p class="mb-0">Tanggal dan Jam</p>
                    </th>
                    <th class="bg-primary text-center">
                      <h5 class="mb-0 f_16">CATATAN IMPLEMENTASI</h5>
                      <p class="mb-0">Diisi dalam format IAR
                        Dilanjutkan dengan catatan monitoring dan implementasi oleh MPP sampai dengan terminasi
                      </p>
                    </th>
                    <th class="bg-primary text-center">
                      <h5 class="mb-0 f_16">NAMA PETUGAS</h5>
                    </th>
                  </tr>

                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="input-group mb-3">
                        <date-picker v-model="dataModalImplementasi.armim_date" :default-value="dataModalImplementasi.armim_date" value-type="format" format="YYYY-MM-DD HH:mm" placeholder="-- Tanggal dan Jam --"></date-picker>
                      </div>

                      <VValidate name="Tanggal dan Jam" v-model="dataModalImplementasi.armim_date" :rules="mrValidation.date" />
                    </td>
                    <td>
                      <b-form-textarea rows="6" v-model="dataModalImplementasi.armim_note"></b-form-textarea>

                      <VValidate name="Catatan Implementasi" v-model="dataModalImplementasi.armim_note" :rules="mrValidation.note" />
                    </td>
                    <td>
                      <div>
                        <b-form-input type="text" v-model="dataModalImplementasi.armim_bright_name" placeholder="Nama" />

                        <VValidate name="Nama Petugas" v-model="dataModalImplementasi.armim_bright_name" :rules="mrValidation.bright_name" />

                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="text-right mt-3">
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal id="formPerencanaanEvaluasi" size="xl" title="Edit Perencanaann & Evaluasi" hide-footer>
      <validation-observer ref="VFormPerencanaan">
        <b-form @submit.prevent="doSubmitModal('VFormPerencanaan')">
          <!-- Perencanaan yang sudah dievaluasi -->
          <div class="row" v-if="dataModalPerencanaanEval.armp_has_evaluated == 'Y'">
            <div class="col-md-12">
              <div class="wrap_width_table">
                <div class="table-responsive scroll_width">
                  <table class="table table-sm  table-bordered">
                    <thead>
                      <tr>
                        <th colspan="3">
                          <h5 class="mb-0 f_16">PERENCANAAN</h5>
                        </th>
                        <th colspan="2">
                          <h5 class="mb-0 f_16">PELAKSANAAN</h5>
                        </th>

                        <th rowspan="2">
                          <h5 class="mb-0 f_16">EVALUASI</h5>
                        </th>

                        <th style="width:200px" rowspan="2">
                          <h5 class="mb-0 f_16">NAMA PETUGAS</h5>
                        </th>

                      </tr>
                      <tr>
                        <th colspan="2">
                          <h5 class="mb-0 f_16">PROGRAM</h5>
                        </th>
                        <th style="min-width:200px">
                          <h5 class="mb-0 f_16">TGL</h5>
                        </th>
                        <th style="min-width:200px">
                          <h5 class="mb-0 f_16">TGL</h5>
                        </th>
                        <th>
                          <h5 class="mb-0 f_16">NAMA PETUGAS</h5>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(v, k) in dataModalPerencanaanEval.arme_koordinasi_dpjp" :key="`dpjp-${k}`">
                        <td :rowspan="dataModalPerencanaanEval.arme_koordinasi_dpjp.length" v-if="k === 0">Koordinasi dengan DPJP</td>
                        <td width="200">
                          {{ v.label }}
                        </td>
                        <td>
                          {{ v.start_date | moment("LLL") }}
                        </td>
                        <td>
                          {{ v.end_date | moment("LLL") }}
                        </td>

                        <td>
                          {{ v.name }}
                        </td>

                        <td>
                          <!-- <div class="input-group">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                            </div>

                            <datepicker input-class="form-control transparent" placeholder="Tanggal Evaluasi" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="v.evaluation_date" :key="`dpjp_ev_date${k}`">
                            </datepicker>
                          </div> -->
                          <date-picker
                            v-model="v.evaluation_date" 
                            type="datetime" 
                            class="w-100"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm"
                            placeholder="-- Tanggal Evaluasi --" 
                            :key="`dpjp_ev_date${k}`"
                          >
                          </date-picker>
                          <VValidate name="Tanggal Evaluasi" v-model="v.evaluation_date" :rules="mrValidation.evaluation_date" :vid="`dpjp_ev_date${k}`" />

                          <div class="mt-2">
                            <b-form-group>
                              <b-form-radio-group @input="updateValueProgram($event, 'status', 'armp_koordinasi_dpjp', k)" :options="Config.mr.ranapStatusMppMampu" :name="`koord_dpjp${k}`" :id="`koord_dpjp${k}`" :key="`koord_dpjp${k}`" v-model="v.status"></b-form-radio-group>

                              <VValidate name="Status" v-model="v.status" :rules="mrValidation.status" :vid="`koord_dpjp${k}`" />
                            </b-form-group>
                          </div>
                        </td>

                        <td>
                          <div>
                            <b-form-input placeholder="Nama" v-model="v.evaluator_name" />

                            <VValidate name="Nama Petugas" v-model="v.evaluator_name" :rules="mrValidation.evaluator_name" :vid="`name_dpjp${k}`" />
                          </div>
                        </td>

                      </tr>
                    </tbody>
                    <tbody>
                      <tr v-for="(v, k) in dataModalPerencanaanEval.arme_koordinasi_ppja" :key="`ppja-${k}`">
                        <td :rowspan="dataModalPerencanaanEval.arme_koordinasi_ppja.length" v-if="k === 0">Koordinasi dengan PPJA (Perawat Penanggung Jawab Asuhan)</td>
                        <td width="200">
                          {{ v.label }}
                        </td>
                        <td>
                          {{ v.start_date | moment("LLL") }}
                        </td>
                        <td>
                          {{ v.end_date | moment("LLL") }}
                        </td>

                        <td>
                          {{ v.name }}
                        </td>

                        <td>
                          <!-- <div class="input-group">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                            </div>

                            <datepicker input-class="form-control transparent" placeholder="Tanggal Evaluasi" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="v.evaluation_date" :key="`ppja_ev_date${k}`">
                            </datepicker>
                          </div> -->
                          <date-picker
                            v-model="v.evaluation_date" 
                            type="datetime" 
                            class="w-100"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm"
                            placeholder="-- Tanggal Evaluasi --" 
                            :key="`ppja_ev_date${k}`"
                          >
                          </date-picker>
                          <VValidate name="Tanggal Evaluasi" v-model="v.evaluation_date" :rules="mrValidation.evaluation_date" :vid="`ppja_ev_date${k}`" />

                          <div class="mt-2">
                            <b-form-group>
                              <b-form-radio-group @input="updateValueProgram($event, 'status', 'armp_koordinasi_ppja', k)" :options="Config.mr.ranapStatusMppMampu" :name="`koord_ppja${k}`" :id="`koord_ppja${k}`" :key="`koord_ppja${k}`" v-model="v.status"></b-form-radio-group>

                              <VValidate name="Status" v-model="v.status" :rules="mrValidation.status" :vid="`koord_ppja${k}`" />
                            </b-form-group>
                          </div>
                        </td>

                        <td>
                          <div>
                            <b-form-input placeholder="Nama" v-model="v.evaluator_name" />

                            <VValidate name="Nama Petugas" v-model="v.evaluator_name" :rules="mrValidation.evaluator_name" :vid="`name_ppja${k}`" />
                          </div>
                        </td>

                      </tr>

                    </tbody>
                    <tbody>
                      <tr v-for="(v, k) in dataModalPerencanaanEval.arme_koordinasi_farmasi_klinis" :key="`farmasi-${k}`">
                        <td :rowspan="dataModalPerencanaanEval.arme_koordinasi_farmasi_klinis.length" v-if="k === 0">Koordinasi dengan Farmasi Klinis</td>
                        <td width="200">
                          {{ v.label }}
                        </td>
                        <td>
                          {{ v.start_date | moment("LLL") }}
                        </td>
                        <td>
                          {{ v.end_date | moment("LLL") }}
                        </td>

                        <td>
                          {{ v.name }}
                        </td>

                        <td>
                          <!-- <div class="input-group">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                            </div>

                            <datepicker input-class="form-control transparent" placeholder="Tanggal Evaluasi" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="v.evaluation_date" :key="`farmasi_ev_date${k}`">
                            </datepicker>
                          </div> -->

                          <date-picker
                            v-model="v.evaluation_date" 
                            type="datetime" 
                            class="w-100"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm"
                            placeholder="-- Tanggal Evaluasi --" 
                            :key="`farmasi_ev_date${k}`"
                          >
                          </date-picker>

                          <VValidate name="Tanggal Evaluasi" v-model="v.evaluation_date" :rules="mrValidation.evaluation_date" :vid="`farmasi_ev_date${k}`" />

                          <div class="mt-2">
                            <b-form-group>
                              <b-form-radio-group @input="updateValueProgram($event, 'status', 'armp_koordinasi_farmasi', k)" :options="Config.mr.ranapStatusMppMampu" :name="`koord_farmasi${k}`" :id="`koord_farmasi${k}`" :key="`koord_farmasi${k}`" v-model="v.status"></b-form-radio-group>

                              <VValidate name="Status" v-model="v.status" :rules="mrValidation.status" :vid="`koord_farmasi${k}`" />
                            </b-form-group>
                          </div>
                        </td>

                        <td>
                          <div>
                            <b-form-input placeholder="Nama" v-model="v.evaluator_name" />

                            <VValidate name="Nama Petugas" v-model="v.evaluator_name" :rules="mrValidation.evaluator_name" :vid="`name_farmasi${k}`" />
                          </div>
                        </td>
                      </tr>
                    </tbody>

                    <tbody>
                      <tr v-for="(v, k) in dataModalPerencanaanEval.arme_koordinasi_nutrisions" :key="`nutrisions-${k}`">
                        <td :rowspan="dataModalPerencanaanEval.arme_koordinasi_nutrisions.length" v-if="k === 0">Koordinasi dengan Nutrions/ahli gizi</td>
                        <td width="200">
                          {{ v.label }}
                        </td>
                        <td>
                          {{ v.start_date | moment("LLL") }}
                        </td>
                        <td>
                          {{ v.end_date | moment("LLL") }}
                        </td>

                        <td>
                          {{ v.name }}
                        </td>

                        <td>
                          <!-- <div class="input-group">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                            </div>

                            <datepicker input-class="form-control transparent" placeholder="Tanggal Evaluasi" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="v.evaluation_date" :key="`nutrisions_ev_date${k}`">
                            </datepicker>
                          </div> -->

                          <date-picker
                            v-model="v.evaluation_date" 
                            type="datetime" 
                            class="w-100"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm"
                            placeholder="-- Tanggal Evaluasi --" 
                            :key="`nutrisions_ev_date${k}`"
                          >
                          </date-picker>

                          <VValidate name="Tanggal Evaluasi" v-model="v.evaluation_date" :rules="mrValidation.evaluation_date" :vid="`nutrisions_ev_date${k}`" />

                          <div class="mt-2">
                            <b-form-group>
                              <b-form-radio-group @input="updateValueProgram($event, 'status', 'armp_koordinasi_nutrisions', k)" :options="Config.mr.ranapStatusMppMampu" :name="`koord_nutrisions${k}`" :id="`koord_nutrisions${k}`" :key="`koord_nutrisions${k}`" v-model="v.status"></b-form-radio-group>

                              <VValidate name="Status" v-model="v.status" :rules="mrValidation.status" :vid="`koord_nutrisions${k}`" />
                            </b-form-group>
                          </div>
                        </td>

                        <td>
                          <div>
                            <b-form-input placeholder="Nama" v-model="v.evaluator_name" />

                            <VValidate name="Nama Petugas" v-model="v.evaluator_name" :rules="mrValidation.evaluator_name" :vid="`name_nutrisions${k}`" />
                          </div>
                        </td>

                      </tr>
                    </tbody>
                    <tbody>
                      <tr v-for="(v, k) in dataModalPerencanaanEval.arme_koordinasi_rehabilitasi" :key="`rehabilitasi-${k}`">
                        <td :rowspan="dataModalPerencanaanEval.arme_koordinasi_rehabilitasi.length" v-if="k === 0">Koordinasi dengan rehabilitasi</td>
                        <td width="200">
                          {{ v.label }}
                        </td>
                        <td>
                          {{ v.start_date | moment("LLL") }}
                        </td>
                        <td>
                          {{ v.end_date | moment("LLL") }}
                        </td>

                        <td>
                          {{ v.name }}
                        </td>

                        <td>
                          <!-- <div class="input-group">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                            </div>

                            <datepicker input-class="form-control transparent" placeholder="Tanggal Evaluasi" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="v.evaluation_date" :key="`rehabilitasi_ev_date${k}`">
                            </datepicker>
                          </div> -->

                          <date-picker
                            v-model="v.evaluation_date" 
                            type="datetime" 
                            class="w-100"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm"
                            placeholder="-- Tanggal Evaluasi --" 
                            :key="`rehabilitasi_ev_date${k}`"
                          >
                          </date-picker>

                          <VValidate name="Tanggal Evaluasi" v-model="v.evaluation_date" :rules="mrValidation.evaluation_date" :vid="`rehabilitasi_ev_date${k}`" />

                          <div class="mt-2">
                            <b-form-group>
                              <b-form-radio-group @input="updateValueProgram($event, 'status', 'armp_koordinasi_rehabilitasi', k)" :options="Config.mr.ranapStatusMppMampu" :name="`koord_rehabilitasi${k}`" :id="`koord_rehabilitasi${k}`" :key="`koord_rehabilitasi${k}`" v-model="v.status"></b-form-radio-group>

                              <VValidate name="Status" v-model="v.status" :rules="mrValidation.status" :vid="`koord_rehabilitasi${k}`" />
                            </b-form-group>
                          </div>
                        </td>

                        <td>
                          <div>
                            <b-form-input placeholder="Nama" v-model="v.evaluator_name" />

                            <VValidate name="Nama Petugas" v-model="v.evaluator_name" :rules="mrValidation.evaluator_name" :vid="`name_rehabilitasi${k}`" />
                          </div>
                        </td>

                      </tr>
                    </tbody>
                    <tbody>
                      <tr v-for="(v, k) in dataModalPerencanaanEval.arme_koordinasi_petugas" :key="`petugas-${k}`">
                        <td :rowspan="dataModalPerencanaanEval.arme_koordinasi_petugas.length" v-if="k === 0">Koordinasi dengan Petugas</td>
                        <td width="200">
                          {{ v.label }}
                        </td>
                        <td>
                          {{ v.start_date | moment("LLL") }}
                        </td>
                        <td>
                          {{ v.end_date | moment("LLL") }}
                        </td>

                        <td>
                          {{ v.name }}
                        </td>

                        <td>
                          <!-- <div class="input-group">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                            </div>

                            <datepicker input-class="form-control transparent" placeholder="Tanggal Evaluasi" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="v.evaluation_date" :key="`petugas_ev_date${k}`">
                            </datepicker>
                          </div> -->

                          <date-picker
                            v-model="v.evaluation_date" 
                            type="datetime" 
                            class="w-100"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm"
                            placeholder="-- Tanggal Evaluasi --" 
                            :key="`petugas_ev_date${k}`"
                          >
                          </date-picker>

                          <VValidate name="Tanggal Evaluasi" v-model="v.evaluation_date" :rules="mrValidation.evaluation_date" :vid="`petugas_ev_date${k}`" />

                          <div class="mt-2">
                            <b-form-group>
                              <b-form-radio-group @input="updateValueProgram($event, 'status', 'armp_koordinasi_petugas', k)" :options="Config.mr.ranapStatusMppMampu" :name="`koord_petugas${k}`" :id="`koord_petugas${k}`" :key="`koord_petugas${k}`" v-model="v.status"></b-form-radio-group>

                              <VValidate name="Status" v-model="v.status" :rules="mrValidation.status" :vid="`koord_petugas${k}`" />
                            </b-form-group>
                          </div>
                        </td>

                        <td>
                          <div>
                            <b-form-input placeholder="Nama" v-model="v.evaluator_name" />

                            <VValidate name="Nama Petugas" v-model="v.evaluator_name" :rules="mrValidation.evaluator_name" :vid="`name_petugas${k}`" />
                          </div>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>

          <!-- Perencanaan yang belum dievaluasi -->
          <div class="row" v-else>
            <div class="col-md-12">
              <div class="wrap_width_table">
                <div class="table-responsive">
                  <table class="table table-sm  table-bordered">
                    <thead>
                      <tr>
                        <th colspan="3">
                          <h5 class="mb-0 f_16">PERENCANAAN</h5>
                        </th>
                        <th colspan="2">
                          <h5 class="mb-0 f_16">PELAKSANAAN</h5>
                        </th>

                      </tr>
                      <tr>
                        <th colspan="2">
                          <h5 class="mb-0 f_16">PROGRAM</h5>
                        </th>
                        <th style="min-width:200px">
                          <h5 class="mb-0 f_16">TGL</h5>
                        </th>
                        <th style="min-width:200px">
                          <h5 class="mb-0 f_16">TGL</h5>
                        </th>
                        <th>
                          <h5 class="mb-0 f_16">NAMA & PARAF</h5>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr 
                      v-for="(v, k) in [...mrKoordinasiDPJP, ...dataModalPerencanaanEval.armp_koordinasi_dpjp.filter(kd => kd.is_other)]" :key="`dpjp-${k}`">
                        <td :rowspan="[...mrKoordinasiDPJP, ...dataModalPerencanaanEval.armp_koordinasi_dpjp.filter(kd => kd.is_other)].length" v-if="k === 0">Koordinasi dengan DPJP</td>
                        <td width="200">
                          <b-form-checkbox 
                            :id="`dpjpc-${k}`" 
                            v-model="dataModalPerencanaanEval.armp_koordinasi_dpjp" 
                            :name="`dpjpc-${k}`" :key="`dpjpc-${k}`" 
                            :value="getValueKoordinasi('armp_koordinasi_dpjp', k) || { ...v, ...dumpProperty, key_master: k }"                             
                          >
                            {{ v.label }}
                          </b-form-checkbox>
                        </td>
                        <td>
                          <!-- <div class="input-group mb-3" v-if="checkDataRow('armp_koordinasi_dpjp', k)">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                            </div>

                            <datepicker input-class="form-control transparent" placeholder="Tanggal Pelaksanaan" class="my-datepicker" calendar-class="my-datepicker_calendar" :value="getValueKoordinasiProp('armp_koordinasi_dpjp', k, 'start_date')" @input="updateValueProgram($event, 'start_date', 'armp_koordinasi_dpjp', k)">
                            </datepicker>
                          </div> -->                          
                          <date-picker
                            v-model="(dataModalPerencanaanEval.armp_koordinasi_dpjp[getIndexDataRow('armp_koordinasi_dpjp', k)]||{}).start_date"
                            type="datetime" 
                            class="w-100"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'start_date', 'armp_koordinasi_dpjp', k)"
                            v-if="checkDataRow('armp_koordinasi_dpjp', k)"
                          >
                          </date-picker>
                        </td>
                        <td>
                          <!-- <div class="input-group mb-3" v-if="checkDataRow('armp_koordinasi_dpjp', k)">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                            </div>

                            <datepicker input-class="form-control transparent" placeholder="Tanggal Pelaksanaan" class="my-datepicker" calendar-class="my-datepicker_calendar" :value="getValueKoordinasiProp('armp_koordinasi_dpjp', k, 'end_date')" @input="updateValueProgram($event, 'end_date', 'armp_koordinasi_dpjp', k)">
                            </datepicker>
                          </div> -->

                          <date-picker
                            v-model="(dataModalPerencanaanEval.armp_koordinasi_dpjp[getIndexDataRow('armp_koordinasi_dpjp', k)]||{}).end_date"
                            type="datetime" 
                            class="w-100"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'end_date', 'armp_koordinasi_dpjp', k)"
                            v-if="checkDataRow('armp_koordinasi_dpjp', k)"
                          >
                          </date-picker>
                        </td>

                        <td>
                          <div v-if="checkDataRow('armp_koordinasi_dpjp', k)">
                            <b-form-input type="text" :value="getValueKoordinasiProp('armp_koordinasi_dpjp', k, 'name')" placeholder="Nama" @change="updateValueProgram($event, 'name', 'armp_koordinasi_dpjp', k)" />
                          </div>
                        </td>

                      </tr>
                    </tbody>
                    <tbody>
                      <tr v-for="(v, k) in [...mrKoordinasiPPJA, ...dataModalPerencanaanEval.armp_koordinasi_ppja.filter(kd => kd.is_other)]" :key="`ppja-${k}`">
                        <td :rowspan="[...mrKoordinasiPPJA, ...dataModalPerencanaanEval.armp_koordinasi_ppja.filter(kd => kd.is_other)].length" v-if="k === 0">Koordinasi dengan PPJA (Perawat Penanggung Jawab Asuhan)</td>
                        <td width="200">
                          <b-form-checkbox 
                            :id="`ppjac-${k}`" 
                            v-model="dataModalPerencanaanEval.armp_koordinasi_ppja" 
                            :name="`ppjac-${k}`" :key="`ppjac-${k}`" 
                            :value="getValueKoordinasi('armp_koordinasi_ppja', k) || { ...v, ...dumpProperty, key_master: k }"                             
                            v-if="!v.is_other"
                          >
                            {{ v.label }}
                          </b-form-checkbox>

                          <b-form-input 
                            placeholder="Nama Lainnya" 
                            @change="updateOtherOptions($event, v.key_other_data, 'koordinasi_ppja', 'label')" 
                            :value="getValueKoordinasiProp('armp_koordinasi_ppja', k, 'label')"
                            v-else 
                          />
                        </td>
                        <td>
                          <!-- <div class="input-group mb-3" v-if="checkDataRow('armp_koordinasi_ppja', k)">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                            </div>

                            <datepicker input-class="form-control transparent" placeholder="Tanggal Pelaksanaan" class="my-datepicker" calendar-class="my-datepicker_calendar" :value="getValueKoordinasiProp('armp_koordinasi_ppja', k, 'start_date')" @input="updateValueProgram($event, 'start_date', 'armp_koordinasi_ppja', k)">
                            </datepicker>
                          </div> -->

                          <date-picker
                            v-model="(dataModalPerencanaanEval.armp_koordinasi_ppja[getIndexDataRow('armp_koordinasi_ppja', k)]||{}).start_date"
                            type="datetime" 
                            class="w-100"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'start_date', 'armp_koordinasi_ppja', k)"
                            v-if="checkDataRow('armp_koordinasi_ppja', k)"
                          >
                          </date-picker>
                        </td>
                        <td>
                          <!-- <div class="input-group mb-3" v-if="checkDataRow('armp_koordinasi_ppja', k)">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                            </div>

                            <datepicker input-class="form-control transparent" placeholder="Tanggal Pelaksanaan" class="my-datepicker" calendar-class="my-datepicker_calendar" :value="getValueKoordinasiProp('armp_koordinasi_ppja', k, 'start_date')" @input="updateValueProgram($event, 'end_date', 'armp_koordinasi_ppja', k)">
                            </datepicker>
                          </div> -->

                          <date-picker
                            v-model="(dataModalPerencanaanEval.armp_koordinasi_ppja[getIndexDataRow('armp_koordinasi_ppja', k)]||{}).end_date"
                            type="datetime" 
                            class="w-100"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'end_date', 'armp_koordinasi_ppja', k)"
                            v-if="checkDataRow('armp_koordinasi_ppja', k)"
                          >
                          </date-picker>
                        </td>                    
                        <td>
                          <div v-if="checkDataRow('armp_koordinasi_ppja', k)">
                            <b-form-input type="text" :value="getValueKoordinasiProp('armp_koordinasi_ppja', k, 'name')" placeholder="Nama" @change="updateValueProgram($event, 'name', 'armp_koordinasi_ppja', k)" />
                          </div>
                        </td>

                      </tr>
                      <tr>
                        <td>
                          <span></span>
                        </td>
                        <td colspan="4">
                          <a href="javascript:;" class="btn btn-sm alpha-info border-info" @click="addOtherOptions('armp_koordinasi_ppja', 'mrKoordinasiPPJA')">
                            <i class="icon-plus2 mr-1"></i>Tambah
                          </a>
                        </td>
                      </tr>

                    </tbody>
                    <tbody>
                      <tr v-for="(v, k) in [...mrKoordinasiFarmasiKlinis, ...dataModalPerencanaanEval.armp_koordinasi_farmasi_klinis.filter(kd => kd.is_other)]" :key="`farmasi-${k}`">
                        <td :rowspan="[...mrKoordinasiFarmasiKlinis, ...dataModalPerencanaanEval.armp_koordinasi_farmasi_klinis.filter(kd => kd.is_other)].length" v-if="k === 0">Koordinasi dengan Farmasi Klinis</td>
                        <td width="200">
                          <b-form-checkbox 
                            :id="`farmasic-${k}`" 
                            v-model="dataModalPerencanaanEval.armp_koordinasi_farmasi_klinis" 
                            :name="`farmasic-${k}`" :key="`farmasic-${k}`" 
                            :value="getValueKoordinasi('armp_koordinasi_farmasi_klinis', k) || { ...v, ...dumpProperty, key_master: k }"                             
                            v-if="!v.is_other"
                          >
                            {{ v.label }}
                          </b-form-checkbox>

                          <b-form-input 
                            placeholder="Nama Lainnya" 
                            @change="updateOtherOptions($event, v.key_other_data, 'koordinasi_farmasi', 'label')" 
                            :value="getValueKoordinasiProp('armp_koordinasi_farmasi_klinis', k, 'label')"
                            v-else 
                          />
                        </td>
                        <td>
                          <!-- <div class="input-group mb-3" v-if="checkDataRow('armp_koordinasi_farmasi_klinis', k)">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                            </div>

                            <datepicker input-class="form-control transparent" placeholder="Tanggal Pelaksanaan" class="my-datepicker" calendar-class="my-datepicker_calendar" :value="getValueKoordinasiProp('armp_koordinasi_farmasi_klinis', k, 'start_date')" @input="updateValueProgram($event, 'start_date', 'armp_koordinasi_farmasi', k)">
                            </datepicker>
                          </div> -->
                          <date-picker
                            v-model="(dataModalPerencanaanEval.armp_koordinasi_farmasi_klinis[getIndexDataRow('armp_koordinasi_farmasi_klinis', k)]||{}).start_date"
                            type="datetime" 
                            class="w-100"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'start_date', 'armp_koordinasi_farmasi_klinis', k)"
                            v-if="checkDataRow('armp_koordinasi_farmasi_klinis', k)"
                          >
                          </date-picker>
                        </td>
                        <td>
                          <!-- <div class="input-group mb-3" v-if="checkDataRow('armp_koordinasi_farmasi_klinis', k)">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                            </div>

                            <datepicker input-class="form-control transparent" placeholder="Tanggal Pelaksanaan" class="my-datepicker" calendar-class="my-datepicker_calendar" :value="getValueKoordinasiProp('armp_koordinasi_farmasi_klinis', k, 'start_date')" @input="updateValueProgram($event, 'end_date', 'armp_koordinasi_farmasi', k)">
                            </datepicker>
                          </div> -->

                          <date-picker
                            v-model="(dataModalPerencanaanEval.armp_koordinasi_farmasi_klinis[getIndexDataRow('armp_koordinasi_farmasi_klinis', k)]||{}).end_date"
                            type="datetime" 
                            class="w-100"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'end_date', 'armp_koordinasi_farmasi_klinis', k)"
                            v-if="checkDataRow('armp_koordinasi_farmasi_klinis', k)"
                          >
                          </date-picker>
                        </td>

                        <td>
                          <div v-if="checkDataRow('armp_koordinasi_farmasi_klinis', k)">
                            <b-form-input type="text" :value="getValueKoordinasiProp('armp_koordinasi_farmasi_klinis', k, 'name')" placeholder="Nama" @change="updateValueProgram($event, 'name', 'armp_koordinasi_farmasi', k)" />
                          </div>
                        </td>

                      </tr>
                      <tr>
                        <td>
                          <span></span>
                        </td>
                        <td colspan="4">
                          <a href="javascript:;" class="btn btn-sm alpha-info border-info" @click="addOtherOptions('armp_koordinasi_farmasi', 'mrKoordinasiFarmasiKlinis')">
                            <i class="icon-plus2 mr-1"></i>Tambah
                          </a>
                        </td>
                      </tr>
                    </tbody>

                    <tbody>
                      <tr v-for="(v, k) in [...mrKoordinasiNutrisions, ...dataModalPerencanaanEval.armp_koordinasi_nutrisions.filter(kd => kd.is_other)]" :key="`nutrisions-${k}`">
                        <td :rowspan="[...mrKoordinasiNutrisions, ...dataModalPerencanaanEval.armp_koordinasi_nutrisions.filter(kd => kd.is_other)].length" v-if="k === 0">Koordinasi dengan Nutrions/ahli gizi</td>
                        <td width="200">
                          <b-form-checkbox 
                            :id="`nutrisionsc-${k}`" 
                            v-model="dataModalPerencanaanEval.armp_koordinasi_nutrisions" 
                            :name="`nutrisionsc-${k}`" :key="`nutrisionsc-${k}`" 
                            :value="getValueKoordinasi('armp_koordinasi_nutrisions', k) || { ...v, ...dumpProperty, key_master: k }"                             
                            v-if="!v.is_other"
                          >
                            {{ v.label }}
                          </b-form-checkbox>

                          <b-form-input 
                            placeholder="Nama Lainnya" 
                            @change="updateOtherOptions($event, v.key_other_data, 'koordinasi_nutrisions', 'label')" 
                            :value="getValueKoordinasiProp('armp_koordinasi_nutrisions', k, 'label')"
                            v-else 
                          />
                        </td>
                        <td>
                          <!-- <div class="input-group mb-3" v-if="checkDataRow('armp_koordinasi_nutrisions', k)">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                            </div>

                            <datepicker input-class="form-control transparent" placeholder="Tanggal Pelaksanaan" class="my-datepicker" calendar-class="my-datepicker_calendar" :value="getValueKoordinasiProp('armp_koordinasi_nutrisions', k, 'start_date')" @input="updateValueProgram($event, 'start_date', 'armp_koordinasi_nutrisions', k)">
                            </datepicker>
                          </div> -->

                          <date-picker
                            v-model="(dataModalPerencanaanEval.armp_koordinasi_nutrisions[getIndexDataRow('armp_koordinasi_nutrisions', k)]||{}).start_date"
                            type="datetime" 
                            class="w-100"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'start_date', 'armp_koordinasi_nutrisions', k)"
                            v-if="checkDataRow('armp_koordinasi_nutrisions', k)"
                          >
                          </date-picker>
                        </td>
                        <td>
                          <!-- <div class="input-group mb-3" v-if="checkDataRow('armp_koordinasi_nutrisions', k)">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                            </div>

                            <datepicker input-class="form-control transparent" placeholder="Tanggal Pelaksanaan" class="my-datepicker" calendar-class="my-datepicker_calendar" :value="getValueKoordinasiProp('armp_koordinasi_nutrisions', k, 'start_date')" @input="updateValueProgram($event, 'end_date', 'armp_koordinasi_nutrisions', k)">
                            </datepicker>
                          </div> -->

                          <date-picker
                            v-model="(dataModalPerencanaanEval.armp_koordinasi_nutrisions[getIndexDataRow('armp_koordinasi_nutrisions', k)]||{}).start_date"
                            type="datetime" 
                            class="w-100"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'end_date', 'armp_koordinasi_nutrisions', k)"
                            v-if="checkDataRow('armp_koordinasi_nutrisions', k)"
                          >
                          </date-picker>
                        </td>

                        <td>
                          <div v-if="checkDataRow('armp_koordinasi_nutrisions', k)">
                            <b-form-input type="text" :value="getValueKoordinasiProp('armp_koordinasi_nutrisions', k, 'name')" placeholder="Nama" @change="updateValueProgram($event, 'name', 'armp_koordinasi_nutrisions', k)" />
                          </div>
                        </td>

                      </tr>
                      <tr>
                        <td>
                          <span></span>
                        </td>
                        <td colspan="4">
                          <a href="javascript:;" class="btn btn-sm alpha-info border-info" @click="addOtherOptions('armp_koordinasi_nutrisions', 'mrKoordinasiNutrisions')">
                            <i class="icon-plus2 mr-1"></i>Tambah
                          </a>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr v-for="(v, k) in [...mrKoordinasiRehabilitasi, ...dataModalPerencanaanEval.armp_koordinasi_rehabilitasi.filter(kd => kd.is_other)]" :key="`rehabilitasi-${k}`">
                        <td :rowspan="[...mrKoordinasiRehabilitasi, ...dataModalPerencanaanEval.armp_koordinasi_rehabilitasi.filter(kd => kd.is_other)].length" v-if="k === 0">Koordinasi dengan rehabilitasi</td>
                        <td width="200">
                          <b-form-checkbox 
                            :id="`rehabilitasic-${k}`"
                             v-model="dataModalPerencanaanEval.armp_koordinasi_rehabilitasi" 
                             :name="`rehabilitasic-${k}`" 
                             :key="`rehabilitasic-${k}`" 
                             :value="getValueKoordinasi('armp_koordinasi_rehabilitasi', k) || { ...v, ...dumpProperty, key_master: k }"                              
                             v-if="!v.is_other"
                            >
                            {{ v.label }}
                          </b-form-checkbox>
                          
                          <b-form-input 
                            placeholder="Nama Lainnya" 
                            @change="updateOtherOptions($event, v.key_other_data, 'koordinasi_rehabilitasi', 'label')" 
                            :value="getValueKoordinasiProp('armp_koordinasi_rehabilitasi', k, 'label')"
                            v-else 
                          />
                        </td>
                        <td>
                          <!-- <div class="input-group mb-3" v-if="checkDataRow('armp_koordinasi_rehabilitasi', k)">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                            </div>

                            <datepicker input-class="form-control transparent" placeholder="Tanggal Pelaksanaan" class="my-datepicker" calendar-class="my-datepicker_calendar" :value="getValueKoordinasiProp('armp_koordinasi_rehabilitasi', k, 'start_date')" @input="updateValueProgram($event, 'start_date', 'armp_koordinasi_rehabilitasi', k)">
                            </datepicker>
                          </div> -->

                          <date-picker
                            v-model="(dataModalPerencanaanEval.armp_koordinasi_rehabilitasi[getIndexDataRow('armp_koordinasi_rehabilitasi', k)]||{}).start_date"
                            type="datetime" 
                            class="w-100"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'start_date', 'armp_koordinasi_rehabilitasi', k)"
                            v-if="checkDataRow('armp_koordinasi_rehabilitasi', k)"
                          >
                          </date-picker>
                        </td>
                        <td>
                          <!-- <div class="input-group mb-3" v-if="checkDataRow('armp_koordinasi_rehabilitasi', k)">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                            </div>

                            <datepicker input-class="form-control transparent" placeholder="Tanggal Pelaksanaan" class="my-datepicker" calendar-class="my-datepicker_calendar" :value="getValueKoordinasiProp('armp_koordinasi_rehabilitasi', k, 'start_date')" @input="updateValueProgram($event, 'end_date', 'armp_koordinasi_rehabilitasi', k)">
                            </datepicker>
                          </div> -->

                          <date-picker
                            v-model="(dataModalPerencanaanEval.armp_koordinasi_rehabilitasi[getIndexDataRow('armp_koordinasi_rehabilitasi', k)]||{}).end_date"
                            type="datetime" 
                            class="w-100"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'end_date', 'armp_koordinasi_rehabilitasi', k)"
                            v-if="checkDataRow('armp_koordinasi_rehabilitasi', k)"
                          >
                          </date-picker>
                        </td>

                        <td>
                          <div v-if="checkDataRow('armp_koordinasi_rehabilitasi', k)">
                            <b-form-input type="text" :value="getValueKoordinasiProp('armp_koordinasi_rehabilitasi', k, 'name')" placeholder="Nama" @change="updateValueProgram($event, 'name', 'armp_koordinasi_rehabilitasi', k)" />
                          </div>
                        </td>

                      </tr>
                      <tr>
                        <td>
                          <span></span>
                        </td>
                        <td colspan="4">
                          <a href="javascript:;" class="btn btn-sm alpha-info border-info" @click="addOtherOptions('armp_koordinasi_rehabilitasi', 'mrKoordinasiRehabilitasi')">
                            <i class="icon-plus2 mr-1"></i>Tambah
                          </a>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr v-for="(v, k) in [...mrKoordinasiPetugas, ...dataModalPerencanaanEval.armp_koordinasi_petugas.filter(kd => kd.is_other)]" :key="`petugas-${k}`">
                        <td :rowspan="[...mrKoordinasiPetugas, ...dataModalPerencanaanEval.armp_koordinasi_petugas.filter(kd => kd.is_other)].length" v-if="k === 0">Koordinasi dengan Petugas</td>
                        <td width="200">
                          <b-form-checkbox 
                            :id="`petugasc-${k}`" 
                            v-model="dataModalPerencanaanEval.armp_koordinasi_petugas" 
                            :name="`petugasc-${k}`" :key="`petugasc-${k}`" 
                            :value="getValueKoordinasi('armp_koordinasi_petugas', k) || { ...v, ...dumpProperty, key_master: k }"                             
                          >
                            {{ v.label }}
                          </b-form-checkbox>
                        </td>
                        <td>
                          <!-- <div class="input-group mb-3" v-if="checkDataRow('armp_koordinasi_petugas', k)">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                            </div>

                            <datepicker input-class="form-control transparent" placeholder="Tanggal Pelaksanaan" class="my-datepicker" calendar-class="my-datepicker_calendar" :value="getValueKoordinasiProp('armp_koordinasi_petugas', k, 'start_date')" @input="updateValueProgram($event, 'start_date', 'armp_koordinasi_petugas', k)">
                            </datepicker>
                          </div> -->

                          <date-picker
                            v-model="(dataModalPerencanaanEval.armp_koordinasi_petugas[getIndexDataRow('armp_koordinasi_petugas', k)]||{}).start_date"
                            type="datetime" 
                            class="w-100"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'start_date', 'armp_koordinasi_petugas', k)"
                            v-if="checkDataRow('armp_koordinasi_petugas', k)"
                          >
                          </date-picker>
                        </td>
                        <td>
                          <!-- <div class="input-group mb-3" v-if="checkDataRow('armp_koordinasi_petugas', k)">
                            <div class="input-group-prepend calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                            </div>

                            <datepicker input-class="form-control transparent" placeholder="Tanggal Pelaksanaan" class="my-datepicker" calendar-class="my-datepicker_calendar" :value="getValueKoordinasiProp('armp_koordinasi_petugas', k, 'start_date')" @input="updateValueProgram($event, 'end_date', 'armp_koordinasi_petugas', k)">
                            </datepicker>
                          </div> -->

                          <date-picker
                            v-model="(dataModalPerencanaanEval.armp_koordinasi_petugas[getIndexDataRow('armp_koordinasi_petugas', k)]||{}).end_date"
                            type="datetime" 
                            class="w-100"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'end_date', 'armp_koordinasi_petugas', k)"
                            v-if="checkDataRow('armp_koordinasi_petugas', k)"
                          >
                          </date-picker>
                        </td>

                        <td>
                          <div v-if="checkDataRow('armp_koordinasi_petugas', k)">
                            <b-form-input type="text" :value="getValueKoordinasiProp('armp_koordinasi_petugas', k, 'name')" placeholder="Nama" @change="updateValueProgram($event, 'name', 'armp_koordinasi_petugas', k)" />
                          </div>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>

          <div class="text-right mt-3">
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>

  <div v-else>
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" /> 

    <div class="content">
      <kajian-awal v-if="viewType == 'KAJIAN_AWAL'" />
      <perencanaan v-else-if="viewType == 'PERENCANAAN'" />
      <implementasi v-else-if="viewType == 'IMPLEMENTASI'" />
      <evaluasi v-else />
    </div>
  </div>

</div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import KajianAwal from './KajianAwal.vue'
import Perencanaan from './Perencanaan.vue'
import Implementasi from './Implementasi.vue'
import Evaluasi from './Evaluasi.vue'

import PatientInfo from '@/components/Ranap/PatientInfo.vue'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

const moment = require('moment')

const _ = global._
import $ from 'jquery'
import Gen from '@/libs/Gen.js'

export default {
  extends: GlobalVue,
  components: {
    KajianAwal,
    Perencanaan,
    Implementasi,
    Evaluasi,
    PatientInfo,
    DatePicker,
    DateRangePicker
  },
  computed: {
    isDetail() {
      return this.$route.query.isDetail
    },
    isDoc() {
      return this.$route.query.isDoc
    },
    passToSub() {
      return {
        Config: this.Config,
        mrValidation: this.mrValidation,
        rowPasien: this.rowPasien,
        isShowBangsal: this.isShowBangsal,
        infoBangsal: this.infoBangsal,
        katalogBangsal: this.katalogBangsal
      }
    },
    viewType() {
      return this.$route.query.viewType
    }
  },
  data() {
    return {
      datePickerConfig: {
        startDate: new Date(moment().subtract(7, 'days')),
        endDate: new Date(),
        autoApply: true,
        ranges: {
          'Hari Ini': [new Date(), new Date()],
          '7 Hari Terakhir': [new Date(moment().subtract(7, 'days')), new Date()],
          '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
        },
        applyClass: 'btn-sm btn-primary',
        cancelClass: 'btn-sm btn-light',
        locale: {
          applyLabel: 'Terapkan',
          cancelLabel: 'Batal',
          direction: 'ltr',
          format: 'mm/dd/yyyy',
          separator: ' - ',
        }
      },
      dateRange: {
        startDate: new Date(moment().subtract(7, 'days')),
        endDate: new Date(),
      },

      idKey: 'aranres_id',
      dumpProperty: {
        name: null,
        start_date: null,
        end_date: null,
      },

      row: {},
      rowEdit: {},
      rowPasien: {},
      rowAdditional: {},
      mRanapBangsal: [],
      mDPJP: [],
      openPerawatPelaksana: false,

      mrValidation: {},
      mrKoordinasiDPJP: [],
      mrKoordinasiPPJA: [],
      mrKoordinasiFarmasiKlinis: [],
      mrKoordinasiNutrisions: [],
      mrKoordinasiRehabilitasi: [],
      mrKoordinasiPetugas: [],

      mrScreening: [],
      mrAdvocate: [],
      mrIdentifikasiMasalah: [],
      mrMppPlanning: [],
      mrFamilyDecisions: [],
      mrHambatan: [],
      mrRiwayatKesehatan: [],
      mrAsuransi: [],
      mrRiwayatAlternatif: [],
      mrPemahamanKesehatan: [],
      mrHarapanAsuhan: [],
      mrDischargePlanning: [],
      mrSummary: [],
      mrValueRiwayatKesehatan: [],
      mrValueBehaviorSpiritual: [],
      mrValueEnvironment: [],
      mrValueFamilySupport: [],
      mrValueFinancial: [],
      mrValueAsuransi: [],

      dataKajianAwal: {},
      dataImplementasi: {},
      dataPerencanaanEvaluasi: {},

      dataModal: {},

      dataModalImplementasi: {},
      dataModalPerencanaanEval: {
        armp_koordinasi_dpjp: [],
        armp_koordinasi_ppja: [],
        armp_koordinasi_farmasi_klinis: [],
        armp_koordinasi_nutrisions: [],
        armp_koordinasi_rehabilitasi: [],
        armp_koordinasi_petugas: [],
      },

      patientData: {},
      loading: {
        patientInfo: false,
      },

      formData: {
        implementasi: [],
        koordinasi_dpjp: [],
        koordinasi_ppja: [],
        koordinasi_farmasi: [],
        koordinasi_nutrisions: [],
        koordinasi_rehabilitasi: [],
        koordinasi_petugas: [],
      }
    }
  },
  mounted() {
    const {
      dpjp,
      ranap_bangsal
    } = this.$route.query
    this.filter.dpjp = +dpjp || 'ALL'
    this.filter.ranap_bangsal = +ranap_bangsal || this.user.bu_bangsal_id

    this.doConvertDate()
    this.apiGet()

    if (this.isList) {
      this.apiGetResume()
    } else {
      this.getPatientInfo()
      this.getFormData()
    }

    setTimeout(() => {
      this.setSlide()
    }, 1000)
  },
  methods: {
    getIndexDataRow(keyData, key_master){
      const indexKeyData = (this.dataModalPerencanaanEval[keyData]||[]).findIndex(dt => dt.key_master === key_master)
      return indexKeyData
    },
    updateValueProgram(value, propertyData, keyData, key_master){
      const indexKeyData = this.dataModalPerencanaanEval[keyData].findIndex(dt => dt.key_master === key_master)
      this.$set(this.dataModalPerencanaanEval[keyData][indexKeyData], propertyData, value)
    },
    updateOtherOptions(value, key, keyData, data){
      this.$set(this.dataModalPerencanaanEval[keyData][key], data, value)
      if(data == "label"){
        this.$set(this.dataModalPerencanaanEval[keyData][key], 'value', value.configify())
      }
    },
    addOtherOptions(rowData, data){
      this[data].push({
        is_other: true,
        key_other_data: (this.dataModalPerencanaanEval[rowData].length - 1) + 1
      })

      this.dataModalPerencanaanEval[rowData].push({
        value: null,
        label: null,
        name: this.user.fullName,
        key_master: (this[data].length - 1)
      })
    },
    checkDataRow(keyData, key){
      const indexKeyData = this.dataModalPerencanaanEval[keyData].findIndex(dt => dt.key_master === key)
      return indexKeyData >= 0
    },
    editImplementasi(data) {
      this.$set(this, 'dataModalImplementasi', {
        ...data,
      })
      this.$bvModal.show('formImplementasi')
    },

    editPerencanaanEvaluasi(data) {
      this.$set(this, 'dataModalPerencanaanEval', data)
      this.$bvModal.show('formPerencanaanEvaluasi')
    },
    openDetail(data) {
      this.$bvModal.show('detailData')
      this.getDetail(data)
    },

    getDetail(data){
      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-detail",
            id_registrasi: data.aranr_id
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
        
        this.$set(this, 'dataModal', { ...this.dataModal, ...data })
      })
    },

    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.params.pageSlug
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
      })
    },

    getFormData() {
      let type
      if (this.viewType == "KAJIAN_AWAL") {
        type = "get-form-kajian-awal"
      } else if (this.viewType == "PERENCANAAN") {
        type = "get-form-perencanaan"
      } else if (this.viewType == "IMPLEMENTASI") {
        type = "get-form-implementasi"
      } else if (this.viewType == "EVALUASI") {
        type = "get-form-evaluasi"
      }

      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type,
            id_registrasi: this.$route.params.pageSlug
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
      })
    },

    toMonitoring(regID) {
      this.$router.push({
        name: 'RanapMonitoring',
        params: {
          pageSlug: regID
        }
      }).catch(() => {})
    },
    setSlide() {
      const responsiveTable = document.querySelector('.table-responsive')
      if (responsiveTable) {
        const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
        const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
        if (responsiveTableScroll) {
          // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
          Array.from(responsiveTableScrollActions).forEach(action => {
            action.addEventListener('click', e => {
              const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100

              responsiveTable.scroll({
                left: scrollAmount,
                behavior: 'smooth'
              })
            })
          })
        }
      }
    },
    doResetData() {
      this.doReset()
      this.dateRange = {
        startDate: new Date(moment().subtract(6, 'days')),
        endDate: new Date(),
      }
      this.doConvertDate()
    },
    doRefreshData() {
      this.apiGet()
      window.scrollTo(0, 0)
    },
    apiGetResume() {
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      Gen.apiRest(
        "/get/" + this.modulePage + '/resume', {
          params: Object.assign({}, paramsQuery || {})
        }
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
      })
    },
    doConvertDate() {
      this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
      this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
    },
    doPageOne() {
      this.filter.page = 1
    },
    doFill() {
      this.doPageOne()
      this.doFilter()
    },
    doSearch: _.debounce(function () {
      this.doPageOne()
      this.doFilter()
    }, 100),

    getValueKoordinasi(keyData, keyMaster){
      return this.dataModalPerencanaanEval[keyData].find(dt => dt.key_master == keyMaster)
    },

    getValueKoordinasiProp(keyData, keyMaster, prop){
      const findData = this.dataModalPerencanaanEval[keyData].find(dt => dt.key_master == keyMaster)
      return findData ? findData[prop] : (prop == "name" ? this.user.fullName : null)
    },

    // Submit Form Actions
    doSubmitKajianAwal(data) {
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            ...data,
            id_registrasi: this.$route.params.pageSlug
          }
        },
        "POST"
      ).then(resp => {
        this.$swal.close()
        this.$swal({
          title: resp.data.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then(result => {
          if (result.value) {
            this.$router.push({
              name: "RanapMPP"
            })
          }
        })
      }).catch(() => {
        this.$swal.close()

        return this.$swal({
          icon: 'error',
          text: 'Terjadi Kesalahan Yang Tidak Diketahui'
        })
      })
    },

    doSubmitImplementasi(data) {
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            ...data,
            id_registrasi: this.$route.params.pageSlug || data.id_registrasi
          }
        },
        "POST"
      ).then(resp => {
        this.$swal.close()
        this.$swal({
          title: resp.data.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then(result => {
          if (result.value) {
            if(data.fromModal){
              this.getDetail(this.dataModal)
              this.$bvModal.hide('formImplementasi')            
            } else {
              this.$router.push({ name: this.modulePage })
            }
          }
        })
      }).catch(() => {
        this.$swal.close()

        return this.$swal({
          icon: 'error',
          text: 'Terjadi Kesalahan Yang Tidak Diketahui'
        })
      })
    },

    doSubmitPerencanaan(data) {
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            ...data,
            id_registrasi: data.id_registrasi || this.$route.params.pageSlug
          }
        },
        "POST"
      ).then(resp => {
        this.$swal.close()
        this.$swal({
          title: resp.data.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then(result => {
          if (result.value) {
            if(data.fromModal){
              this.getDetail(this.dataModal)
              this.$bvModal.hide('formPerencanaanEvaluasi')            
            } else {
              this.$router.push({ name: this.modulePage })
            }
          }
        })
      }).catch(() => {
        this.$swal.close()

        return this.$swal({
          icon: 'error',
          text: 'Terjadi Kesalahan Yang Tidak Diketahui'
        })
      })
    },

    doSubmitEvaluasi(data) {
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            ...data,
            id_registrasi: this.$route.params.pageSlug
          }
        },
        "POST"
      ).then(resp => {
        this.$swal.close()
        this.$swal({
          title: resp.data.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then(result => {
          if (result.value) {
            this.$router.push({
              name: "RanapMPP"
            })
          }
        })
      }).catch(() => {
        this.$swal.close()

        return this.$swal({
          icon: 'error',
          text: 'Terjadi Kesalahan Yang Tidak Diketahui'
        })
      })
    },

    doSubmitModal(ref) {
      this.$refs[ref].validate().then(success => {
        if (!success) return false

        this.$swal.fire({
          icon: "warning",
          title: "<h2 class='font-weight-bold'>Sudah Yakin Dengan Data yang Diisi?</h2>",
          html: `
            <span style="font-size: 16px">
              Pastikan untuk mengisikan data yang sesuai, karena data yang sudah disubmit sudah tidak dapat diubah
            </span>              
          `,

          showCancelButton: true,
          cancelButtonText: "Cek Ulang",
          confirmButtonText: "Lanjutkan & Submit"
        }).then(button => {
          if (button.isConfirmed) {
            if(ref === "VFormImplementasi"){
              this.doSubmitImplementasi({
                implementasi: [{
                  ...this.dataModalImplementasi, 
                }],
                id_registrasi: this.dataModal.aranr_id,
                type: 'submit-implementasi',
                fromModal: true
              })
            } else if(ref === "VFormPerencanaan"){
              this.doSubmitPerencanaan({
                ...this.dataModalPerencanaanEval,
                id_registrasi: this.dataModal.aranr_id,
                type: this.dataModalPerencanaanEval.armp_has_evaluated == 'Y' ? 'update-evaluasi' : 'update-perencanaan',
                fromModal: true
              })
            }
          }
        })
      })
    },
    updateValues(e) {
      this.doConvertDate()
      this.doFill()
    },
  },
  filters: {
    date(val) {
      return val ? moment(val).format("D MMM YYYY") : ""
    }
  },
  watch: {
    $route() {
      this.apiGet()

      if (this.isList) {
        this.apiGetResume()
      } else {
        this.getPatientInfo()
        this.getFormData()
      }

      setTimeout(() => {
        if (this.isList) {
          this.setSlide()
        }
      }, 1000)
    },
    '$route.params.pageSlug': function () {
      this.$set(this, 'filter', {})
    },
    'openPerawatPelaksana': function (v) {
      if (!v) {
        this.apiGet()
      }
    }
  }
}
</script>
