<template>
<div>
  <router-link :to="{ name: 'RanapMPP' }" class="text-dark">
    <i class="icon-arrow-left52 mr-1"></i>
    <span class="font-weight-semibold">Kembali</span>
  </router-link>

  <div class="card mt-2">
    <div class="card-header bg-white">
      <div class="row align-items-center">
        <div class="col-md-auto">
          <div class="row align-items-center">
            <div class="col-md-auto">
              <h5 class="card-title font-weight-semibold">Pemulangan (P3) MPP (Form C) </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <validation-observer ref="VFormPerencanaan">
      <b-form @submit.prevent="doSubmit()">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="wrap_width_table">
                <div class="table-responsive">
                  <table class="table table-sm  table-bordered">
                    <thead>
                      <tr>
                        <th colspan="3">
                          <h5 class="mb-0 f_16">PERENCANAAN</h5>
                        </th>
                        <th colspan="2">
                          <h5 class="mb-0 f_16">PELAKSANAAN</h5>
                        </th>

                      </tr>
                      <tr>
                        <th colspan="2">
                          <h5 class="mb-0 f_16">PROGRAM</h5>
                        </th>
                        <th style="min-width:200px">
                          <h5 class="mb-0 f_16">TGL</h5>
                        </th>
                        <th style="min-width:200px">
                          <h5 class="mb-0 f_16">TGL</h5>
                        </th>
                        <th>
                          <h5 class="mb-0 f_16">NAMA PETUGAS</h5>
                        </th>
                      </tr>
                    </thead>
                    <tbody>                      
                      <tr v-for="(v, k) in $parent.mrKoordinasiDPJP" :key="`dpjp-${k}`">
                        <td :rowspan="$parent.mrKoordinasiDPJP.length" v-if="k === 0">Koordinasi dengan DPJP</td>
                        <td width="200">
                          <b-form-checkbox
                            :id="`dpjpc-${k}`"
                            v-model="formData.koordinasi_dpjp"
                            :name="`dpjpc-${k}`"
                            :key="`dpjpc-${k}`"
                            :value="formData.koordinasi_dpjp[getIndexDataRow('koordinasi_dpjp', k)] || { ...v, ...dumpProperty, key_master: k }"
                            @change="checklistProgram('koordinasi_dpjp'); updateRow(formData);"
                          >
                            {{ v.label }}
                          </b-form-checkbox>
                        </td>
                        <td>
                          <date-picker
                            v-model="(formData.koordinasi_dpjp[getIndexDataRow('koordinasi_dpjp', k)]||{}).start_date"
                            type="datetime"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm" 
                            class="w-100"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'start_date', 'koordinasi_dpjp', k); updateRow(formData);"
                            v-if="checkDataRow('koordinasi_dpjp', k) || v.is_other"
                          >
                          </date-picker>
                          
                          <span v-if="checkDataRow('koordinasi_dpjp', k) || v.is_other">
                           <VValidate 
                              name="Tanggal Awal"
                              :vid="`tpa-${k}`"
                              rules="required"
                              :value="(formData.koordinasi_dpjp[getIndexDataRow('koordinasi_dpjp', k)]||{}).start_date"
                            />                                                        
                          </span>
                        </td>
                        <td>
                          <date-picker
                            v-model="(formData.koordinasi_dpjp[getIndexDataRow('koordinasi_dpjp', k)]||{}).end_date"
                            type="datetime"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm" 
                            class="w-100"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'end_date', 'koordinasi_dpjp', k); updateRow(formData);"
                            v-if="checkDataRow('koordinasi_dpjp', k) || v.is_other"
                          >
                          </date-picker>

                          <span v-if="checkDataRow('koordinasi_dpjp', k) || v.is_other">
                           <VValidate 
                              name="Tanggal Akhir"
                              :vid="`tpakh-${k}`"
                              rules="required"
                              :value="(formData.koordinasi_dpjp[getIndexDataRow('koordinasi_dpjp', k)]||{}).end_date"                              
                            />
                          </span>
                        </td>

                        <td>
                          <div v-if="checkDataRow('koordinasi_dpjp', k) || v.is_other">
                            <b-form-input type="text" placeholder="Nama" :value="$parent.user.fullName" @change="updateValueProgram($event, 'name', 'koordinasi_dpjp', k); updateRow(formData);" />
                          </div>
                        </td>

                      </tr>
                    </tbody>
                    <tbody>
                      <tr v-for="(v, k) in [...$parent.mrKoordinasiPPJA, ...otherKoordinasiPPJA]" :key="`ppja-${k}`">
                        <td :rowspan="[...$parent.mrKoordinasiPPJA, ...otherKoordinasiPPJA].length" v-if="k === 0">Koordinasi dengan PPJA (Perawat Penanggung Jawab Asuhan)</td>
                        <td width="200">
                          <b-form-checkbox
                            :id="`ppjac-${k}`"
                            v-model="formData.koordinasi_ppja"
                            :name="`ppjac-${k}`"
                            :key="`ppjac-${k}`"
                            :value="formData.koordinasi_ppja[getIndexDataRow('koordinasi_ppja', k)] ||  { ...v, ...dumpProperty, key_master: k }"
                            @change="checklistProgram('koordinasi_ppja'); updateRow(formData);"
                            v-if="!v.is_other"
                          >
                            {{ v.label }}
                          </b-form-checkbox>                          

                          <b-form-input 
                            placeholder="Nama Lainnya" 
                            @change="updateOtherOptions($event, v.key_other_data, 'koordinasi_ppja', 'label')" 
                            v-model="(formData.koordinasi_ppja[getIndexDataRow('koordinasi_ppja', k)]||{}).label"
                            v-else 
                          />
                        </td>
                        <td>

                          <date-picker
                            v-model="(formData.koordinasi_ppja[getIndexDataRow('koordinasi_ppja', k)]||{}).start_date" 
                            type="datetime"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm" 
                            class="w-100"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'start_date', 'koordinasi_ppja', k); updateRow(formData);"
                            v-if="checkDataRow('koordinasi_ppja', k) || v.is_other"
                          >
                          </date-picker>

                          <span v-if="checkDataRow('koordinasi_ppja', k) || v.is_other">
                           <VValidate 
                              name="Tanggal Awal"
                              :vid="`tppjaa-${k}`"
                              rules="required"
                              :value="(formData.koordinasi_ppja[getIndexDataRow('koordinasi_ppja', k)] || {}).start_date"
                            />
                          </span>
                        </td>
                        <td>

                          <date-picker
                            v-model="(formData.koordinasi_ppja[getIndexDataRow('koordinasi_ppja', k)] || {}).end_date" 
                            type="datetime"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm" 
                            class="w-100"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'end_date', 'koordinasi_ppja', k); updateRow(formData);"
                            v-if="checkDataRow('koordinasi_ppja', k) || v.is_other"
                          >
                          </date-picker>

                          <span v-if="checkDataRow('koordinasi_ppja', k) || v.is_other">
                           <VValidate 
                              name="Tanggal Akhir"
                              :vid="`tppjaakh-${k}`"
                              rules="required"
                              :value="(formData.koordinasi_ppja[getIndexDataRow('koordinasi_ppja', k)] || {}).end_date"                              
                            />
                          </span>
                        </td>

                        <td>
                          <div v-if="checkDataRow('koordinasi_ppja', k) || v.is_other">
                            <b-form-input type="text" placeholder="Nama" :value="$parent.user.fullName" @change="updateValueProgram($event, 'name', 'koordinasi_ppja', k); updateRow(formData);" />
                          </div>
                        </td>

                      </tr>
                      <tr>
                        <td>
                          <span></span>
                        </td>
                        <td colspan="4">
                          <a href="javascript:;" class="btn btn-sm alpha-info border-info" @click="addOtherOptions('koordinasi_ppja', [...$parent.mrKoordinasiPPJA, ...otherKoordinasiPPJA])">
                            <i class="icon-plus2 mr-1"></i>Tambah
                          </a>
                        </td>
                      </tr>

                    </tbody>
                    <tbody>
                      <tr v-for="(v, k) in [...$parent.mrKoordinasiFarmasiKlinis, ...otherKoordinasiFarmasi]" :key="`farmasi-${k}`">
                        <td :rowspan="[...$parent.mrKoordinasiFarmasiKlinis, ...otherKoordinasiFarmasi].length" v-if="k === 0">Koordinasi dengan Farmasi Klinis</td>
                        <td width="200">
                          <b-form-checkbox
                            :id="`farmasic-${k}`"
                            v-model="formData.koordinasi_farmasi_klinis"
                            :name="`farmasic-${k}`"
                            :key="`farmasic-${k}`"
                            :value="formData.koordinasi_farmasi_klinis[getIndexDataRow('koordinasi_farmasi_klinis', k)] || { ...v, ...dumpProperty, key_master: k }"
                            @change="checklistProgram('koordinasi_farmasi_klinis'); updateRow(formData);"
                            v-if="!v.is_other"
                          >
                            {{ v.label }}
                          </b-form-checkbox>

                          <b-form-input 
                            placeholder="Nama Lainnya" 
                            @change="updateOtherOptions($event, v.key_other_data, 'koordinasi_farmasi_klinis', 'label')" 
                            v-model="(formData.koordinasi_farmasi_klinis[getIndexDataRow('koordinasi_farmasi_klinis', k)]||{}).label"
                            v-else 
                          />
                        </td>
                        <td>

                          <date-picker
                            v-model="(formData.koordinasi_farmasi_klinis[getIndexDataRow('koordinasi_farmasi_klinis', k)]||{}).start_date" 
                            type="datetime"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm" 
                            class="w-100"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'start_date', 'koordinasi_farmasi_klinis', k); updateRow(formData);"
                            v-if="checkDataRow('koordinasi_farmasi_klinis', k) || v.is_other"
                          >
                          </date-picker>

                          <span v-if="checkDataRow('koordinasi_farmasi_klinis', k) || v.is_other">
                           <VValidate 
                              name="Tanggal Awal"
                              :vid="`tpfarmasia-${k}`"
                              rules="required"
                              :value="(formData.koordinasi_farmasi_klinis[getIndexDataRow('koordinasi_farmasi_klinis', k)]||{}).start_date"                              
                            />
                          </span>
                        </td>
                        <td>

                          <date-picker
                            v-model="(formData.koordinasi_farmasi_klinis[getIndexDataRow('koordinasi_farmasi_klinis', k)]||{}).end_date" 
                            type="datetime"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm" 
                            class="w-100"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'end_date', 'koordinasi_farmasi_klinis', k); updateRow(formData);"
                            v-if="checkDataRow('koordinasi_farmasi_klinis', k) || v.is_other"
                          >
                          </date-picker>

                          <span v-if="checkDataRow('koordinasi_farmasi_klinis', k) || v.is_other">
                           <VValidate 
                              name="Tanggal Akhir"
                              :vid="`tpfarmasiakh-${k}`"
                              rules="required"
                              :value="(formData.koordinasi_farmasi_klinis[getIndexDataRow('koordinasi_farmasi_klinis', k)]||{}).end_date"                              
                            />
                          </span>
                        </td>

                        <td>
                          <div v-if="checkDataRow('koordinasi_farmasi_klinis', k) || v.is_other">
                            <b-form-input type="text" placeholder="Nama" :value="$parent.user.fullName" @change="updateValueProgram($event, 'name', 'koordinasi_farmasi_klinis', k); updateRow(formData);" />
                          </div>
                        </td>

                      </tr>
                      <tr>
                        <td>
                          <span></span>
                        </td>
                        <td colspan="4">
                          <a href="javascript:;" class="btn btn-sm alpha-info border-info" @click="addOtherOptions('koordinasi_farmasi_klinis', [...$parent.mrKoordinasiFarmasiKlinis, ...otherKoordinasiFarmasi])">
                            <i class="icon-plus2 mr-1"></i>Tambah
                          </a>
                        </td>
                      </tr>
                    </tbody>

                    <tbody>
                      <tr v-for="(v, k) in [...$parent.mrKoordinasiNutrisions, ...otherKoordinasiNutrisions]" :key="`nutrisions-${k}`">
                        <td :rowspan="[...$parent.mrKoordinasiNutrisions, ...otherKoordinasiNutrisions].length" v-if="k === 0">Koordinasi dengan Nutrions/ahli gizi</td>
                        <td width="200">
                          <b-form-checkbox
                            :id="`nutrisionsc-${k}`"
                            v-model="formData.koordinasi_nutrisions"
                            :name="`nutrisionsc-${k}`"
                            :key="`nutrisionsc-${k}`"
                            :value="formData.koordinasi_nutrisions[getIndexDataRow('koordinasi_nutrisions', k)] || { ...v, ...dumpProperty, key_master: k }"
                            @change="checklistProgram('koordinasi_nutrisions'); updateRow(formData);"
                            v-if="!v.is_other"
                          >
                            {{ v.label }}
                          </b-form-checkbox>

                          <b-form-input 
                            placeholder="Nama Lainnya" 
                            @change="updateOtherOptions($event, v.key_other_data, 'koordinasi_nutrisions', 'label')" 
                            v-model="(formData.koordinasi_nutrisions[getIndexDataRow('koordinasi_nutrisions', k)]||{}).label"
                            v-else 
                          />
                        </td>
                        <td>

                          <date-picker
                            v-model="(formData.koordinasi_nutrisions[getIndexDataRow('koordinasi_nutrisions', k)]||{}).start_date" 
                            type="datetime"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm" 
                            class="w-100"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'start_date', 'koordinasi_nutrisions', k); updateRow(formData);"
                            v-if="checkDataRow('koordinasi_nutrisions', k) || v.is_other"
                          >
                          </date-picker>

                          <span v-if="checkDataRow('koordinasi_nutrisions', k) || v.is_other">
                           <VValidate 
                              name="Tanggal Awal"
                              :vid="`tpnutria-${k}`"
                              rules="required"
                              :value="(formData.koordinasi_nutrisions[getIndexDataRow('koordinasi_nutrisions', k)]||{}).start_date"                              
                            />
                          </span>
                        </td>
                        <td>

                          <date-picker
                            v-model="(formData.koordinasi_nutrisions[getIndexDataRow('koordinasi_nutrisions', k)]||{}).end_date" 
                            type="datetime"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm" 
                            class="w-100"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'end_date', 'koordinasi_nutrisions', k); updateRow(formData);"
                            v-if="checkDataRow('koordinasi_nutrisions', k) || v.is_other"
                          >
                          </date-picker>

                          <span v-if="checkDataRow('koordinasi_nutrisions', k) || v.is_other">
                           <VValidate 
                              name="Tanggal Akhir"
                              :vid="`tpnutriakh-${k}`"
                              rules="required"
                              :value="(formData.koordinasi_nutrisions[getIndexDataRow('koordinasi_nutrisions', k)]||{}).end_date"                              
                            />
                          </span>
                        </td>

                        <td>
                          <div v-if="checkDataRow('koordinasi_nutrisions', k) || v.is_other">
                            <b-form-input type="text" placeholder="Nama" :value="$parent.user.fullName" @change="updateValueProgram($event, 'name', 'koordinasi_nutrisions', k); updateRow(formData);" />
                          </div>
                        </td>

                      </tr>
                      <tr>
                        <td>
                          <span></span>
                        </td>
                        <td colspan="4">
                          <a href="javascript:;" class="btn btn-sm alpha-info border-info" @click="addOtherOptions('koordinasi_nutrisions', [...$parent.mrKoordinasiNutrisions, ...otherKoordinasiNutrisions])">
                            <i class="icon-plus2 mr-1"></i>Tambah
                          </a>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr v-for="(v, k) in [...$parent.mrKoordinasiRehabilitasi, ...otherKoordinasiRehabilitasi]" :key="`rehabilitasi-${k}`">
                        <td :rowspan="[...$parent.mrKoordinasiRehabilitasi, ...otherKoordinasiRehabilitasi].length" v-if="k === 0">Koordinasi dengan rehabilitasi</td>
                        <td width="200">
                          <b-form-checkbox
                            :id="`rehabilitasic-${k}`"
                            v-model="formData.koordinasi_rehabilitasi"
                            :name="`rehabilitasic-${k}`"
                            :key="`rehabilitasic-${k}`"
                            :value="formData.koordinasi_rehabilitasi[getIndexDataRow('koordinasi_rehabilitasi', k)] || { ...v, ...dumpProperty, key_master: k }"
                            @change="checklistProgram('koordinasi_rehabilitasi'); updateRow(formData);"
                            v-if="!v.is_other"
                          >
                            {{ v.label }}
                          </b-form-checkbox>

                          <b-form-input 
                            placeholder="Nama Lainnya" 
                            @change="updateOtherOptions($event, v.key_other_data, 'koordinasi_rehabilitasi', 'label')" 
                            v-model="(formData.koordinasi_rehabilitasi[getIndexDataRow('koordinasi_rehabilitasi', k)]||{}).label"
                            v-else 
                          />
                        </td>
                        <td>

                          <date-picker
                            v-model="(formData.koordinasi_rehabilitasi[getIndexDataRow('koordinasi_rehabilitasi', k)]||{}).start_date"
                            type="datetime"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm" 
                            class="w-100"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'start_date', 'koordinasi_rehabilitasi', k); updateRow(formData);"
                            v-if="checkDataRow('koordinasi_rehabilitasi', k) || v.is_other"
                          >
                          </date-picker>

                          <span v-if="checkDataRow('koordinasi_rehabilitasi', k) || v.is_other">
                           <VValidate 
                              name="Tanggal Awal"
                              :vid="`tprehaba-${k}`"
                              rules="required"
                              :value="(formData.koordinasi_rehabilitasi[getIndexDataRow('koordinasi_rehabilitasi', k)]||{}).start_date"
                            />
                          </span>
                        </td>
                        <td>
                          <date-picker
                            v-model="(formData.koordinasi_rehabilitasi[getIndexDataRow('koordinasi_rehabilitasi', k)]||{}).end_date" 
                            type="datetime"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm" 
                            class="w-100"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'end_date', 'koordinasi_rehabilitasi', k); updateRow(formData);"
                            v-if="checkDataRow('koordinasi_rehabilitasi', k) || v.is_other"
                          >
                          </date-picker>

                          <span v-if="checkDataRow('koordinasi_rehabilitasi', k) || v.is_other">
                           <VValidate 
                              name="Tanggal Akhir"
                              :vid="`tpreahabakh-${k}`"
                              rules="required"
                              :value="(formData.koordinasi_rehabilitasi[getIndexDataRow('koordinasi_rehabilitasi', k)]||{}).end_date"                              
                            />
                          </span>
                        </td>

                        <td>
                          <div v-if="checkDataRow('koordinasi_rehabilitasi', k) || v.is_other">
                            <b-form-input type="text" placeholder="Nama" :value="$parent.user.fullName" @change="updateValueProgram($event, 'name', 'koordinasi_rehabilitasi', k); updateRow(formData);" />
                          </div>
                        </td>

                      </tr>
                      <tr>
                        <td>
                          <span></span>
                        </td>
                        <td colspan="4">
                          <a href="javascript:;" class="btn btn-sm alpha-info border-info" @click="addOtherOptions('koordinasi_rehabilitasi', [...$parent.mrKoordinasiRehabilitasi, ...otherKoordinasiRehabilitasi])">
                            <i class="icon-plus2 mr-1"></i>Tambah
                          </a>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr v-for="(v, k) in $parent.mrKoordinasiPetugas" :key="`petugas-${k}`">
                        <td :rowspan="$parent.mrKoordinasiPetugas.length" v-if="k === 0">Koordinasi dengan Petugas</td>
                        <td width="200">
                          <b-form-checkbox
                            :id="`petugasc-${k}`"
                            v-model="formData.koordinasi_petugas"
                            :name="`petugasc-${k}`"
                            :key="`petugasc-${k}`"
                            :value="formData.koordinasi_petugas[getIndexDataRow('koordinasi_petugas', k)] || { ...v, ...dumpProperty, key_master: k }"
                            @change="checklistProgram('koordinasi_petugas'); updateRow(formData);"
                          >
                            {{ v.label }}
                          </b-form-checkbox>
                        </td>
                        <td>

                          <date-picker
                            v-model="(formData.koordinasi_petugas[getIndexDataRow('koordinasi_petugas', k)]||{}).start_date" 
                            type="datetime"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm" 
                            class="w-100"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'start_date', 'koordinasi_petugas', k); updateRow(formData);"
                            v-if="checkDataRow('koordinasi_petugas', k) || v.is_other"
                          >
                          </date-picker>

                          <span v-if="checkDataRow('koordinasi_petugas', k) || v.is_other">
                           <VValidate 
                              name="Tanggal Awal"
                              :vid="`tppetugasa-${k}`"
                              rules="required"
                              :value="(formData.koordinasi_petugas[getIndexDataRow('koordinasi_petugas', k)]||{}).start_date"                              
                            />
                          </span>
                        </td>
                        <td>

                          <date-picker
                            v-model="(formData.koordinasi_petugas[getIndexDataRow('koordinasi_petugas', k)]||{}).end_date" 
                            type="datetime"
                            value-type="format" 
                            format="YYYY-MM-DD HH:mm" 
                            class="w-100"
                            placeholder="-- Tanggal dan Jam --" 
                            @input="updateValueProgram($event, 'end_date', 'koordinasi_petugas', k); updateRow(formData);"
                            v-if="checkDataRow('koordinasi_petugas', k) || v.is_other"
                          >
                          </date-picker>


                          <span v-if="checkDataRow('koordinasi_petugas', k) || v.is_other">
                           <VValidate 
                              name="Tanggal Akhir"
                              :vid="`tppetguasakh-${k}`"
                              rules="required"
                              :value="(formData.koordinasi_petugas[getIndexDataRow('koordinasi_petugas', k)]||{}).end_date"                              
                            />
                          </span>
                        </td>

                        <td>
                          <div v-if="checkDataRow('koordinasi_petugas', k) || v.is_other">
                            <b-form-input type="text" placeholder="Nama" :value="$parent.user.fullName" @change="updateValueProgram($event, 'name', 'koordinasi_petugas', k); updateRow(formData);" />
                          </div>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="text-right">
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</div>
</template>

<script>
import MonitoringOptions from '@/libs/MonitoringOptions.js'
import Formatter from '@/libs/Formatter.js'
import Gen from '@/libs/Gen'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  name: "FormData",
  components: {
    DatePicker
  },

  data() {
    return {
      dumpProperty: {
        name: null,
        start_date: null,
        end_date: null,
      }
    }
  },

  methods: {
    updateRow(data){
      Gen.apiRest(
        "/do/" + this.$parent.modulePage, {
          data: {
            type: "update-perencanaan",
            id_registrasi: this.$route.params.pageSlug,            
            ...data
          }
        },
        "POST"
      )
    },
    checklistProgram(keyData){
      const data = this.formData[keyData].filter(dt => !dt.is_other).map(dt => ({ ...dt, start_date: dt.start_date || null, end_date: dt.end_date || null, name: this.$parent.user.fullName }))
      const formData = this.formData[keyData].filter(dt => dt.is_other)
      this.$set(this.formData, keyData, JSON.parse(JSON.stringify( [ ...data, ...formData ] )) )
    },
    checkDataRow(keyData, key_master){
      const indexKeyData = this.formData[keyData].findIndex(dt => dt.key_master === key_master)
      return indexKeyData >= 0
    },
    getIndexDataRow(keyData, key_master){
      const indexKeyData = (this.formData[keyData]||[]).findIndex(dt => dt.key_master === key_master)
      return indexKeyData
    },
    updateValueProgram(value, propertyData, keyData, key_master){
      const indexKeyData = this.formData[keyData].findIndex(dt => dt.key_master === key_master)
      this.$set(this.formData[keyData][indexKeyData], propertyData, value)
    },
    updateOtherOptions(value, key, keyData, data){
      const indexKeyData = this.formData[keyData].filter(dt => dt.is_other).findIndex(dt => dt.key_other_data === key)
      this.$set(this.formData[keyData][indexKeyData], data, value)
      if(data == "label"){
        this.$set(this.formData[keyData][indexKeyData], 'value', value.configify())
      }
    },
    addOtherOptions(rowData, data){
      // this.$parent[data].push({
      //   is_other: true,
      //   key_other_data: (this.$parent.row[rowData].length - 1) + 1
      // })

      this.formData[rowData].push({
        value: null,
        label: null,
        is_other: true,
        name: this.$parent.user.fullName,
        start_date: null,
        end_date: null,
        key_other_data: (data.length - 1) + 1,
        key_master: (data.length - 1) + 1
      })

      setTimeout(() => { 
        this.updateRow(this.formData)
      }, 500)
    },
    openHistory() {
      this.$bvModal.show("historyData")

      this.$parent.getHistoryData(1)
    },

    doSubmit() {
      this.$refs['VFormPerencanaan'].validate().then(success => {
        if (!success) return false

        this.$swal.fire({
          icon: "warning",
          title: "<h2 class='font-weight-bold'>Sudah Yakin Dengan Data yang Diisi?</h2>",
          html: `
            <span style="font-size: 16px">
              Pastikan untuk mengisikan data yang sesuai, karena data yang sudah disubmit sudah tidak dapat diubah
            </span>              
          `,

          showCancelButton: true,
          cancelButtonText: "Cek Ulang",
          confirmButtonText: "Lanjutkan & Submit"
        }).then(button => {
          if (button.isConfirmed) {
            this.$parent.doSubmitPerencanaan({
              ...this.formData,
              id_registrasi: this.$route.params.pageSlug,
              is_draft: 'N',
              type: 'update-perencanaan'
            })
          }
        })
      })
    }
  },

  computed: {
    optionsEWS() {
      return MonitoringOptions.EWS
    },
    MonitoringOptions() {
      return MonitoringOptions
    },

    Formatter() {
      return Formatter
    },

    historyData() {
      return this.$parent.tHistoryData
    },

    mrValidation() {
      return this.$parent.mrValidation
    },

    row(){
      return this.$parent.row
    },

    formData(){
      return this.$parent.formData
    },

    otherKoordinasiPPJA(){
     return (this.formData.koordinasi_ppja||[]).filter(kd => kd.is_other) 
    },

    otherKoordinasiFarmasi(){
     return (this.formData.koordinasi_farmasi_klinis||[]).filter(kd => kd.is_other) 
    },

    otherKoordinasiNutrisions(){
     return (this.formData.koordinasi_nutrisions||[]).filter(kd => kd.is_other) 
    },

    otherKoordinasiRehabilitasi(){
     return (this.formData.koordinasi_rehabilitasi||[]).filter(kd => kd.is_other) 
    },


  },
}
</script>
