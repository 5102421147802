<template>
<div>
  <router-link :to="{ name: 'RanapMPP' }" class="text-dark">
    <i class="icon-arrow-left52 mr-1"></i>
    <span class="font-weight-semibold">Kembali</span>
  </router-link>

  <div class="card mt-2">
    <div class="card-header bg-white">
      <div class="row align-items-center">
        <div class="col-md-auto">
          <div class="row align-items-center">
            <div class="col-md-auto">
              <h5 class="card-title font-weight-semibold">Pengkajian Awal MPP</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <validation-observer ref="VFormKajianAwal">
      <b-form @submit.prevent="doSubmit()">
        <div class="card-header">
          <div class="row">
            <div class="col-md-12">
              <table class="table table-sm  table-bordered">
                <tbody>
                  <tr>
                    <th colspan="3" class="bg-primary">IDENTIFIKASI / SKRINING PASIEN</th>
                  </tr>
                  <tr v-for="(v, k) in $parent.mrScreening" :key="k">
                    <td :colspan="v.value != 'LAINNYA' ? 2 : ''">
                      <b-form-checkbox
                        :id="`screening-${k}`"
                        v-model="row.screening"
                        :name="`screening-${k}`"
                        :value="v"
                        @change="updateRow(row)"
                      >
                        {{ v.value == 'LAINNYA' ? `${v.label}, sebutkan` : v.label }}
                      </b-form-checkbox>
                    </td>
                    <td v-if="v.value == 'LAINNYA'">
                      <b-form-input type="text" v-model="v.note" @change="updateRow(row)" />
                      <VValidate 
                        name="Catatan" 
                        v-model="v.note" 
                        :vid="`screen-${k}`" 
                        rules="required"
                        v-if="checkCheckedValue('LAINNYA', row.screening)"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colspan="99">
                      <VValidate 
                        name="IDENTIFIKASI / SKRINING PASIEN"
                        :value="(row.screening||[]).length > 0 ? true : null"
                        :rules="mrValidation.screening"
                      />
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th colspan="2" class="bg-primary">ASESMEN MANAJEMEN PELAYANAN PASIEN</th>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div>
                        <label for="MasukRuangIntensive">Penurunan kemampuan fungsional dan kemandirian pasien dalam
                          pemenuhan ADL’s:<strong class="text-danger">*</strong></label>
                        <div>
                          <div>
                            <b-form-group>
                              <b-form-radio-group
                                v-model="row.fullfillment_adls_status"
                                :options="[]"
                                value-field="value"
                                text-field="label"
                                @change="updateRow(row)"
                              >
                                <b-form-radio v-for="(v, k) in $parent.mrHambatan" :key="`adls-${k}`" :value="v.value">
                                  <div class="d-flex">
                                    <p class="m-0" :class="{ 'label-horizontal' : row.fullfillment_adls_status == 'HAMBATAN' && v.value == 'HAMBATAN' }">
                                      {{ v.label }}<template v-if="
                                        row.fullfillment_adls_status == 'HAMBATAN' &&
                                        v.value == 'HAMBATAN'
                                      ">, sebutkan                                        
                                      </template>
                                    </p>

                                    <template v-if="
                                      row.fullfillment_adls_status == 'HAMBATAN' &&
                                      v.value == 'HAMBATAN'
                                    ">
                                      <div>
                                        <b-form-textarea cols="50" rows="3" type="text" v-model="row.fullfillment_adls_note" @change="updateRow(row)" />

                                        <VValidate
                                          name="Catatan"
                                          v-model="row.fullfillment_adls_note"
                                          :vid="`adls-${k}`"
                                          rules="required"
                                        />
                                      </div>
                                    </template>
                                  </div>
                                </b-form-radio>

                              </b-form-radio-group>

                            </b-form-group>

                            <VValidate 
                              name="Pemenuhan ADL’s"
                              v-model="row.fullfillment_adls_status"
                              :rules="mrValidation.fullfillment_adls_status"
                            />

                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div>
                        <label for="MasukRuangIntensive">Riwayat kesehatan dan/atau kebiasaan:<strong class="text-danger">*</strong></label>
                        <div>
                          <b-form-group>
                            <b-form-radio-group
                              v-model="row.riwayat_kesehatan_status"
                              :options="$parent.mrRiwayatKesehatan"
                              value-field="value"
                              text-field="label"
                              @change="updateRow(row)"
                            ></b-form-radio-group>
                          </b-form-group>
                        </div>
                        <div class="mt-2" v-if="row.riwayat_kesehatan_status == 'ADA'">
                          <b-form-checkbox
                            :id="`mhc-${k}`"
                            v-model="row.riwayat_kesehatan_value"
                            :name="`mhc-${k}`"
                            v-for="(v, k) in $parent.mrValueRiwayatKesehatan"
                            :key="`mhc-${k}`"
                            :value="v"
                            @change="updateRow(row)"
                          >
                            <div class="d-flex">
                              <span>{{ v.label }}</span>
                              <div v-if="checkCheckedValue(v.value, row.riwayat_kesehatan_value)" class="ml-3">
                                <template v-if="v.value == 'OTHER'">
                                  <b-form-textarea rows="3" cols="50" v-model="v.note" @change="updateRow(row)" />
                                  <VValidate
                                    name="Catatan"
                                    v-model="v.note"
                                    :vid="`mhn-${k}`"
                                    rules="required"
                                  />
                                </template>
                              </div>
                            </div>
                          </b-form-checkbox>

                          <VValidate
                            name="Pilihan"
                            :value="row.riwayat_kesehatan_value.length > 0 ? true : null"
                            :vid="`mhc`"
                            rules="required"
                          />
                        </div>

                        <VValidate 
                          name="Riwayat kesehatan dan/atau kebiasaan"
                          v-model="row.riwayat_kesehatan_status"
                          :rules="mrValidation.riwayat_kesehatan_status"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div>
                        <label for="MasukRuangIntensive">Perilaku spiritual, sosial, dan kultural:<strong class="text-danger">*</strong></label>
                        <div>
                          <b-form-group>
                            <b-form-radio-group
                              v-model="row.behavior_spiritual_status"
                              :options="$parent.mrHambatan"
                              value-field="value"
                              text-field="label"
                              @change="updateRow(row)"
                            ></b-form-radio-group>
                          </b-form-group>
                        </div>

                        <div class="mt-2" v-if="row.behavior_spiritual_status == 'HAMBATAN'">
                          <b-form-checkbox
                            :id="`bsc-${k}`"
                            v-model="row.behavior_spiritual_value"
                            :name="`bsc-${k}`"
                            v-for="(v, k) in $parent.mrValueBehaviorSpiritual"
                            :key="`bsc-${k}`"
                            :value="v"
                            @change="updateRow(row)"
                          >
                            <div class="d-flex">
                              <span :class="{ 'label-horizontal': checkCheckedValue(v.value, row.behavior_spiritual_value) }">{{ v.label }}</span>
                              <div v-if="checkCheckedValue(v.value, row.behavior_spiritual_value)" class="ml-3">
                                <b-form-textarea rows="3" cols="50" v-model="v.note" @change="updateRow(row)" />
                                <VValidate
                                  name="Catatan"
                                  v-model="v.note"
                                  :vid="`bsn-${k}`"
                                  rules="required"
                                />
                              </div>
                            </div>
                          </b-form-checkbox>

                          <VValidate
                            name="Pilihan"
                            :value="row.behavior_spiritual_value.length > 0 ? true : null"
                            :vid="`bsc`"
                            rules="required"
                          />
                        </div>

                        <VValidate 
                          name="Perilaku spiritual, sosial, dan kultural"
                          v-model="row.behavior_spiritual_status"
                          :rules="mrValidation.behavior_spiritual_status"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div>
                        <label for="MasukRuangIntensive">Lingkungan dan tempat tinggal:<strong class="text-danger">*</strong></label>
                        <div>
                          <b-form-group>
                            <b-form-radio-group
                              v-model="row.environment_status"
                              :options="$parent.mrHambatan"
                              value-field="value"
                              text-field="label"
                              @change="updateRow(row)"
                            ></b-form-radio-group>
                          </b-form-group>
                        </div>

                        <div class="mt-2" v-if="row.environment_status == 'HAMBATAN'">
                          <b-form-checkbox
                            :id="`ec-${k}`"
                            v-model="row.environment_value"
                            :name="`ec-${k}`"
                            v-for="(v, k) in $parent.mrValueEnvironment"
                            :key="`ec-${k}`"
                            :value="v"
                            @change="updateRow(row)"
                          >
                            <div class="d-flex">
                              <span>{{ v.label }}</span>
                              <div v-if="checkCheckedValue(v.value, row.environment_value)" class="ml-3">
                                <template v-if="v.value == 'OTHER'">
                                  <b-form-textarea rows="3" cols="50" v-model="v.note" @change="updateRow(row)" />
                                  <VValidate
                                    name="Catatan"
                                    v-model="v.note"
                                    :vid="`en-${k}`"
                                    rules="required"
                                  />
                                </template>
                              </div>
                            </div>
                          </b-form-checkbox>

                          <VValidate
                            name="Pilihan"
                            :value="row.environment_value.length > 0 ? true : null"
                            :vid="`ec`"
                            rules="required"
                          />
                        </div>

                        <VValidate 
                          name="Lingkungan dan tempat tinggal"
                          v-model="row.environment_status"
                          :rules="mrValidation.environment_status"
                        />

                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div>
                        <label for="MasukRuangIntensive">Tersedia dukungan keluarga dan kemampuan untuk melanjutkan perawatan::<strong class="text-danger">*</strong></label>
                        <div>
                          <b-form-group>
                            <b-form-radio-group
                              v-model="row.family_support_status"
                              :options="$parent.mrHambatan"
                              value-field="value"
                              text-field="label"
                              @change="updateRow(row)"
                            ></b-form-radio-group>
                          </b-form-group>
                        </div>

                        <div class="mt-2" v-if="row.family_support_status == 'HAMBATAN'">
                          <b-form-checkbox
                            :id="`fstc-${k}`"
                            v-model="row.family_support_value"
                            :name="`fstc-${k}`"
                            v-for="(v, k) in $parent.mrValueFamilySupport"
                            :key="`fstc-${k}`"
                            :value="v"
                            @change="updateRow(row)"
                          >
                            <div class="d-flex">
                              <span>{{ v.label }}</span>
                              <div v-if="checkCheckedValue(v.value, row.family_support_value)" class="ml-3">
                                <template v-if="v.value == 'OTHER'">
                                  <b-form-textarea rows="3" cols="50" v-model="v.note" @change="updateRow(row)" />
                                  <VValidate
                                    name="Catatan"
                                    v-model="v.note"
                                    :vid="`fstn-${k}`"
                                    rules="required"
                                  />
                                </template>
                              </div>
                            </div>
                          </b-form-checkbox>

                          <VValidate
                            name="Pilihan"
                            :value="row.family_support_value.length > 0 ? true : null"
                            :vid="`fstc`"
                            rules="required"
                          />
                        </div>

                        <VValidate 
                          name="Dukungan keluarga"
                          v-model="row.family_support_status"
                          :rules="mrValidation.family_support_status"
                        />

                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div>
                        <label for="MasukRuangIntensive">Kemampuan finansial:<strong class="text-danger">*</strong></label>
                        <div>
                          <b-form-group>
                            <b-form-radio-group
                              v-model="row.financial_status"
                              :options="$parent.mrHambatan"
                              value-field="value"
                              text-field="label"
                              @change="updateRow(row)"
                            ></b-form-radio-group>
                          </b-form-group>
                        </div>

                        <div class="mt-2" v-if="row.financial_status == 'HAMBATAN'">
                          <b-form-checkbox
                            :id="`fsc-${k}`"
                            v-model="row.financial_value"
                            :name="`fsc-${k}`"
                            v-for="(v, k) in $parent.mrValueFinancial"
                            :key="`fsc-${k}`"
                            :value="v"
                            @change="updateRow(row)"
                          >
                            <div class="d-flex">
                              <span>{{ v.label }}</span>
                              <div v-if="checkCheckedValue(v.value, row.financial_value)" class="ml-3">
                                <template v-if="v.value == 'OTHER'">
                                  <b-form-textarea rows="3" cols="50" v-model="v.note" @change="updateRow(row)" />
                                  <VValidate
                                    name="Catatan"
                                    v-model="v.note"
                                    :vid="`fsn-${k}`"
                                    rules="required"
                                  />
                                </template>
                              </div>
                            </div>
                          </b-form-checkbox>

                          <VValidate
                            name="Pilihan"
                            :value="row.financial_value.length > 0 ? true : null"
                            :vid="`fsc`"
                            rules="required"
                          />
                        </div>

                        <VValidate 
                          name="Kemampuan Finansial"
                          v-model="row.financial_status"
                          :rules="mrValidation.financial_status"
                        />

                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      <div>
                        <label for="MasukRuangIntensive">Status Asuransi:<strong class="text-danger">*</strong></label>
                        <div>
                          <b-form-group>
                            <b-form-radio-group
                              v-model="row.insurance_status"
                              :options="$parent.mrAsuransi"
                              value-field="value"
                              text-field="label"
                              @change="updateRow(row)"
                            ></b-form-radio-group>
                          </b-form-group>
                        </div>

                        <div class="mt-2" v-if="row.insurance_status == 'TIDAK_AKTIF'">
                          <b-form-checkbox
                            :id="`insurancec-${k}`"
                            v-model="row.insurance_value"
                            :name="`insurancec-${k}`"
                            v-for="(v, k) in $parent.mrValueAsuransi"
                            :key="`insurancec-${k}`"
                            :value="v"
                            @change="updateRow(row)"
                          >
                            <div class="d-flex">
                              <span>{{ v.label }}</span>
                              <div v-if="checkCheckedValue(v.value, row.insurance_value)" class="ml-3">
                                <template v-if="v.value == 'KENDALA'">
                                  <b-form-textarea rows="3" cols="50" v-model="v.note" @change="updateRow(row)" />
                                  <VValidate
                                    name="Catatan"
                                    v-model="v.note"
                                    :vid="`insurancen-${k}`"
                                    rules="required"
                                  />
                                </template>
                              </div>
                            </div>
                          </b-form-checkbox>

                          <VValidate
                            name="Pilihan"
                            :value="row.insurance_value.length > 0 ? true : null"
                            :vid="`mhc` "
                          />
                        </div>

                        <VValidate 
                          name="Status Asuransi"
                          v-model="row.insurance_status"
                          :rules="mrValidation.insurance_status"
                        />

                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div>
                        <label for="MasukRuangIntensive">Riwayat pengobatan alternatif dan/atau Non Medis:<strong class="text-danger">*</strong></label>
                        <div>
                          <b-form-group>
                            <b-form-radio-group
                              v-model="row.history_alternative"
                              :options="[]"
                              value-field="value"
                              text-field="label"
                              @change="updateRow(row)"
                            >
                              <b-form-radio v-for="(v, k) in $parent.mrRiwayatAlternatif" :key="`halternative-${k}`" :value="v.value">
                              <div class="d-flex">
                                  <p class="m-0" :class="{ 'label-horizontal-120' : row.history_alternative == 'ADA' && v.value == 'ADA' }">
                                    {{ v.label }}<template v-if="
                                      row.history_alternative == 'ADA' &&
                                      v.value == 'ADA'
                                    ">, sebutkan                                        
                                    </template>
                                  </p>

                                  <template v-if="
                                    row.history_alternative == 'ADA' &&
                                    v.value == 'ADA'
                                  ">
                                    <div>
                                      <b-form-textarea cols="50" rows="3" type="text" v-model="row.history_alternative_note" @change="updateRow(row)" />

                                      <VValidate
                                        name="Catatan"
                                        v-model="row.history_alternative_note"
                                        :vid="`halternative-${k}`"
                                        rules="required"
                                      />
                                    </div>
                                  </template>
                                </div>
                              </b-form-radio>

                            </b-form-radio-group>

                            <VValidate 
                              name="Riwayat pengobatan alternatif"
                              v-model="row.history_alternative"
                              :rules="mrValidation.history_alternative"
                            />

                          </b-form-group>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      <div>
                        <label for="MasukRuangIntensive">Pemahaman terhadap kesehatan:<strong class="text-danger">*</strong></label>
                        <div>
                          <b-form-group>
                            <b-form-radio-group
                              v-model="row.health_understanding"
                              :options="[]"
                              value-field="value"
                              text-field="label"
                              @change="updateRow(row)"
                            >
                              <b-form-radio v-for="(v, k) in $parent.mrPemahamanKesehatan" :key="`hunderstanding-${k}`" :value="v.value">
                                <div class="d-flex">
                                  <p class="m-0" :class="{ 'label-horizontal-140' : row.health_understanding == 'KURANG' && v.value == 'KURANG' }">
                                    {{ v.label }}<template v-if="
                                      row.health_understanding == 'KURANG' &&
                                      v.value == 'KURANG'
                                    ">, sebutkan                                        
                                    </template>
                                  </p>

                                  <template v-if="
                                    row.health_understanding == 'KURANG' &&
                                    v.value == 'KURANG'
                                  ">
                                    <div>
                                      <b-form-textarea cols="50" rows="3" type="text" v-model="row.health_understanding_note" @change="updateRow(row)" />

                                      <VValidate
                                        name="Catatan"
                                        v-model="row.health_understanding_note"
                                        :vid="`hunderstanding-${k}`"
                                        rules="required"
                                      />
                                    </div>
                                  </template>
                                </div>
                              </b-form-radio>

                            </b-form-radio-group>

                            <VValidate 
                              name="Pemahaman Kesehatan"
                              v-model="row.health_understanding"
                              :rules="mrValidation.health_understanding"
                            />

                          </b-form-group>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      <div>
                        <label for="MasukRuangIntensive">Harapan terhadap asuhan:<strong class="text-danger">*</strong></label>
                        <div>
                          <b-form-group>
                            <b-form-radio-group
                              v-model="row.expectations_of_care"
                              :options="[]"
                              value-field="value"
                              text-field="label"
                              @change="updateRow(row)"
                            >
                              <b-form-radio v-for="(v, k) in $parent.mrHarapanAsuhan" :key="`eoc-${k}`" :value="v.value" :class="{ 'mt-3' : row.expectations_of_care == 'OTHER' && v.value == 'OTHER' }">
                                <div class="d-flex">
                                  <p class="m-0" :class="{ 'label-horizontal-120' : row.expectations_of_care == 'OTHER' && v.value == 'OTHER' }">
                                    {{ v.label }}<template v-if="
                                      row.expectations_of_care == 'OTHER' &&
                                      v.value == 'OTHER'
                                    ">, sebutkan                                        
                                    </template>
                                  </p>

                                  <template v-if="
                                    row.expectations_of_care == 'OTHER' &&
                                    v.value == 'OTHER'
                                  ">
                                    <div>
                                      <b-form-textarea cols="50" rows="3" type="text" v-model="row.expectations_of_care_note" @change="updateRow(row)" />

                                      <VValidate
                                        name="Catatan"
                                        v-model="row.expectations_of_care_note"
                                        :vid="`eoc-${k}`"
                                        rules="required"
                                      />
                                    </div>
                                  </template>
                                </div>
                              </b-form-radio>

                            </b-form-radio-group>

                            <VValidate 
                              name="Hapan terhadap asuhan"
                              v-model="row.expectations_of_care"
                              :rules="mrValidation.expectations_of_care"
                            />

                          </b-form-group>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      <div>
                        <label for="MasukRuangIntensive">Discharge Planning:<strong class="text-danger">*</strong></label>
                        <div>
                          <b-form-group>
                            <b-form-radio-group
                              v-model="row.discharge_planning"
                              :options="[]"
                              value-field="value"
                              text-field="label"
                              @change="updateRow(row)"
                            >
                              <b-form-radio v-for="(v, k) in $parent.mrDischargePlanning" :key="`dp-${k}`" :value="v.value">
                                <div class="d-flex">
                                  <p class="m-0" :class="{ 'label-horizontal' : row.discharge_planning == 'DIBUTUHKAN' && v.value == 'DIBUTUHKAN' }">
                                    {{ v.label }}<template v-if="
                                      row.discharge_planning == 'DIBUTUHKAN' &&
                                      v.value == 'DIBUTUHKAN'
                                    ">, sebutkan                                        
                                    </template>
                                  </p>

                                  <template v-if="
                                    row.discharge_planning == 'DIBUTUHKAN' &&
                                    v.value == 'DIBUTUHKAN'
                                  ">
                                    <div>
                                      <b-form-textarea cols="50" rows="3" type="text" v-model="row.discharge_planning_note" @change="updateRow(row)" />

                                      <VValidate
                                        name="Catatan"
                                        v-model="row.discharge_planning_note"
                                        :vid="`dp-${k}`"
                                        rules="required"
                                      />
                                    </div>
                                  </template>
                                </div>
                              </b-form-radio>

                            </b-form-radio-group>

                            <VValidate 
                              name="Discharge Planning"
                              v-model="row.discharge_planning"
                              :rules="mrValidation.discharge_planning"
                            />

                          </b-form-group>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      <div>
                        <label for="MasukRuangIntensive">Aspek Legal / Advokasi:<strong class="text-danger">*</strong></label>
                        <div>
                          <b-form-group>
                            <b-form-radio-group
                              v-model="row.advocate"
                              :options="[]"
                              value-field="value"
                              text-field="label"
                              @change="updateRow(row)"
                            >
                              <b-form-radio v-for="(v, k) in $parent.mrAdvocate" :key="`advocate-${k}`" :value="v.value">
                                <div class="d-flex">
                                  <p class="m-0" :class="{ 'label-horizontal' : row.advocate == 'DIBUTUHKAN' && v.value == 'DIBUTUHKAN' }">
                                    {{ v.label }}<template v-if="
                                      row.advocate == 'DIBUTUHKAN' &&
                                      v.value == 'DIBUTUHKAN'
                                    ">, sebutkan                                        
                                    </template>
                                  </p>

                                  <template v-if="
                                    row.advocate == 'DIBUTUHKAN' &&
                                    v.value == 'DIBUTUHKAN'
                                  ">
                                    <div>
                                      <b-form-textarea cols="50" rows="3" type="text" v-model="row.advocate_note" @change="updateRow(row)" />

                                      <VValidate
                                        name="Catatan"
                                        v-model="row.advocate_note"
                                        :vid="`advocate-${k}`"
                                        rules="required"
                                      />
                                    </div>
                                  </template>
                                </div>
                              </b-form-radio>

                            </b-form-radio-group>

                            <VValidate 
                              name="Aspek Legal / Advokasi"
                              v-model="row.advocate"
                              :rules="mrValidation.advocate"
                            />

                          </b-form-group>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="2" class="bg-primary">IDENTIFIKASI MASALAH DAN KESEMPATAN</th>
                  </tr>
                  <tr v-for="(v, k) in $parent.mrIdentifikasiMasalah" :key="`im-${k}`">
                    <td :colspan="v.value != 'LAINNYA' ? 2 : ''">
                      <b-form-checkbox
                        :id="`im-${k}`"
                        v-model="row.identifikasi_masalah"
                        :name="`im-${k}`"
                        :value="v"
                        @change="updateRow(row)"
                      >
                        {{ v.value == 'LAINNYA' ? `${v.label}, sebutkan` : v.label }}
                      </b-form-checkbox>
                    </td>
                    <td v-if="v.value == 'LAINNYA'">
                      <b-form-input type="text" v-model="v.note" @change="updateRow(row)" />

                      <VValidate name="Catatan" v-model="v.note" :vid="`imkn-${k}`" rules="required" v-if="checkCheckedValue('LAINNYA', row.identifikasi_masalah)" />
                    </td>
                  </tr>

                  <tr>
                    <td colspan="99">
                      <VValidate 
                        name="IDENTIFIKASI MASALAH DAN KESEMPATAN"
                        :value="(row.identifikasi_masalah||[]).length > 0 ? true : null"
                        :rules="mrValidation.identifikasi_masalah"
                      />
                    </td>
                  </tr>

                  <tr>
                    <th colspan="2" class="bg-primary">PERENCANAAN MPP</th>
                  </tr>
                  <tr v-for="(v, k) in $parent.mrMppPlanning" :key="`mpp-planning${k}`">
                    <td :colspan="v.value != 'LAINNYA' ? 2 : ''">
                      <b-form-checkbox
                        :id="`mpp-planning-${k}`"
                        v-model="row.mpp_planning"
                        :name="`mpp-planning-${k}`"
                        :value="v"
                        @change="updateRow(row)"
                      >
                        {{ v.value == 'LAINNYA' ? `${v.label}, sebutkan` : v.label }}
                      </b-form-checkbox>
                    </td>
                    <td v-if="v.value == 'LAINNYA'">
                      <b-form-input type="text" v-model="v.note" @change="updateRow(row)" />
                      <VValidate name="Catatan" v-model="v.note" :vid="`pmppn-${k}`" rules="required" v-if="checkCheckedValue('LAINNYA', row.mpp_planning)" />
                    </td>
                  </tr>

                  <tr>
                    <td colspan="99">
                      <VValidate 
                        name="PERENCANAAN MPP"
                        :value="(row.mpp_planning||[]).length > 0 ? true : null"
                        :rules="mrValidation.mpp_planning"
                      />
                    </td>
                  </tr>

                  <tr>
                    <th colspan="2" class="bg-primary">KEPUTUSAN KELUARGA</th>
                  </tr>
                  <tr v-for="(v, k) in $parent.mrFamilyDecisions" :key="`fd-${k}`">
                    <td :colspan="v.value != 'LAINNYA' ? 2 : ''">
                      <b-form-checkbox
                        :id="`fc-${k}`"
                        v-model="row.family_decisions"
                        :name="`fc-${k}`"
                        :value="v"
                        @change="updateRow(row)"
                      >
                        {{ v.value == 'LAINNYA' ? `${v.label}, sebutkan` : v.label }}
                      </b-form-checkbox>
                    </td>
                    <td v-if="v.value == 'LAINNYA'">
                      <b-form-input type="text" v-model="v.note" @change="updateRow(row)" />
                      <VValidate name="Catatan" v-model="v.note" :vid="`kkn-${k}`" rules="required" v-if="checkCheckedValue('LAINNYA', row.family_decisions)" />
                    </td>
                  </tr>

                  <tr>
                    <td colspan="99">
                      <VValidate 
                        name="KEPUTUSAN KELUARGA"
                        :value="(row.family_decisions||[]).length > 0 ? true : null"
                        :rules="mrValidation.family_decisions"
                      />
                    </td>
                  </tr>

                  <tr>
                    <th colspan="2" class="bg-primary">KESIMPULAN</th>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div>
                        <label for="MasukRuangIntensive">PASIEN INI PERLU DIKELOLA MPP
                          :<strong class="text-danger">*</strong></label>
                        <div>
                          <b-form-group>
                            <b-form-radio-group
                              v-model="row.summary"
                              :options="$parent.mrSummary"
                              value-field="value"
                              text-field="label"
                              @change="updateRow(row)"
                            ></b-form-radio-group>
                          </b-form-group>

                          <VValidate 
                            name="Kesimpulan"
                            v-model="row.summary"
                            :rules="mrValidation.summary"
                          />
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td colspan="2">
                      <div>
                        <label for="CatatanMPP">Catatan MPP</label>
                        <div>
                          <b-form-textarea name="CatatanMPP" id="CatatanMPP" rows="2" v-model="row.mpp_note" placeholder="Catatan" @change="updateRow(row)"></b-form-textarea>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="text-right">
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</div>
</template>

<script>
import MonitoringOptions from '@/libs/MonitoringOptions.js'
import Formatter from '@/libs/Formatter.js'
import Gen from '@/libs/Gen.js'

export default {
  name: "FormData",
  components: {
  },

  data() {
    return {
      formData: {}
    }
  },

  methods: {
    updateRow(data){
      Gen.apiRest(
        "/do/" + this.$parent.modulePage,
        {
          data: {
            ...data, 
            type: "update-kajian-awal",
            id_registrasi: this.$route.params.pageSlug
          } 
        },
        "POST"
      ).then(res => {
        if(!data.id){
          this.$set(data, 'id', res.data.id)
        }
      })
    },
    checkCheckedValue(value, data){
      return data.map(dt => dt.value).indexOf(value) >= 0
    },
    openHistory() {
      this.$bvModal.show("historyData")

      this.$parent.getHistoryData(1)
    },

    doSubmit() {
      this.$refs['VFormKajianAwal'].validate().then(success => {
        if (!success) return false

        this.$swal.fire({
          icon: "warning",
          title: "<h2 class='font-weight-bold'>Sudah Yakin Dengan Data yang Diisi?</h2>",
          html: `
            <span style="font-size: 16px">
              Pastikan untuk mengisikan data yang sesuai, karena data yang sudah disubmit sudah tidak dapat diubah
            </span>              
          `,

          showCancelButton: true,
          cancelButtonText: "Cek Ulang",
          confirmButtonText: "Lanjutkan & Submit"
        }).then(button => {
          if (button.isConfirmed) {
            this.$parent.doSubmitKajianAwal({
              ...this.row,
              type: 'submit-kajian-awal'
            })
          }
        })
      })
    }
  },

  computed: {
    optionsEWS() {
      return MonitoringOptions.EWS
    },
    MonitoringOptions() {
      return MonitoringOptions
    },

    Formatter() {
      return Formatter
    },

    historyData() {
      return this.$parent.tHistoryData
    },

    mrValidation() {
      return this.$parent.mrValidation
    },

    row(){
      return this.$parent.row
    }

  },
}
</script>
