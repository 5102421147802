<template>
<div>
  <router-link :to="{ name: 'RanapMPP' }" class="text-dark mb-3">
    <i class="icon-arrow-left52 mr-1"></i>
    <span class="font-weight-semibold">Kembali</span>
  </router-link>

  <div class="card mt-2">
    <div class="card-header bg-white">
      <div class="row align-items-center">
        <div class="col-md-auto">
          <div class="row align-items-center">
            <div class="col-md-auto">
              <h5 class="card-title font-weight-semibold">Catatan Implementasi (Form B) </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <validation-observer ref="VFormImplementasi">
      <b-form @submit.prevent="doSubmit()">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <table class="table table-sm  table-bordered">
                <thead>
                  <tr>
                    <th class="bg-success text-center">
                      <h5 class="mb-0 f_16">PELAKSANAAN</h5>
                      <p class="mb-0">Tanggal dan Jam</p>
                    </th>
                    <th class="bg-primary text-center">
                      <h5 class="mb-0 f_16">CATATAN IMPLEMENTASI</h5>
                      <p class="mb-0">Diisi dalam format IAR
                        Dilanjutkan dengan catatan monitoring dan implementasi oleh MPP sampai dengan terminasi
                      </p>
                    </th>
                    <th class="bg-primary text-center">
                      <h5 class="mb-0 f_16">NAMA PETUGAS</h5>
                    </th>
                    <th class="bg-primary text-center">
                      <h5 class="mb-0 f_16">Action</h5>
                    </th>
                  </tr>

                </thead>
                <tbody>
                  <tr v-for="(v, k) in formData.implementasi" :key="k">
                    <td>
                      <div class="input-group mb-3">
                        <date-picker v-model="v.date" type="datetime" value-type="format" format="YYYY-MM-DD HH:mm" placeholder="-- Tanggal dan Jam --" @input="updateRow(v)"></date-picker>
                      </div>

                      <VValidate 
                        name="Tanggal dan Jam" 
                        v-model="v.date" 
                        :rules="mrValidation.date"
                      />
                    </td>
                    <td>
                      <b-form-textarea rows="6" v-model="v.note" :key="`note${k}`" @change="updateRow(v)"></b-form-textarea>

                      <VValidate 
                        name="Catatan Implementasi" 
                        v-model="v.note" 
                        :rules="mrValidation.note"
                      />
                    </td>
                    <td>
                      <div>
                        <b-form-input type="text" v-model="v.bright_name" placeholder="Nama" :key="`bright_name${k}`" @change="updateRow(v)" />

                        <VValidate 
                          name="Tanda Tangan dan Nama Terang" 
                          v-model="v.bright_name" 
                          :rules="mrValidation.bright_name"
                        />

                      </div>
                    </td>

                    <td>
                      <a href="javascript:;" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" v-b-tooltip.hover="'Hapus'" v-if="formData.implementasi.length > 1" @click="removeImplementasi(v, k)">
                        <i class="icon-cancel-circle2"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="3" class="text-center">
                      <a href="javascript:;" class="btn btn-sm alpha-info border-info" @click="addImplementasi()">
                        <i class="icon-plus2 mr-1"></i>Tambah
                      </a>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="text-right">
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</div>
</template>

<script>
import Gen from '@/libs/Gen'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  name: "FormData",
  components: { 
    DatePicker
   },

  data() {
    return {
      
    }
  },

  methods: {
    updateRow(data){
      Gen.apiRest(
        "/do/" + this.$parent.modulePage,
        {
          data: {
            ...data, 
            type: "update-implementasi",
            id_registrasi: this.$route.params.pageSlug
          } 
        },
        "POST"
      ).then(res => {
        if(!data.id){
          this.$set(data, 'id', res.data.id)
        }
      })
    },
    addImplementasi(){
      this.formData.implementasi.push({
        date: null,
        note: null,
        bright_name: this.$parent.user.fullName
      })
    },
    removeImplementasi(data, key){
      Gen.apiRest(
        "/do/" + this.$parent.modulePage,
        {
          data: {
            ...data, 
            type: "delete-implementasi",
            id_registrasi: this.$route.params.pageSlug
          } 
        },
        "POST"
      ).then(() => {
        this.formData.implementasi.splice(key, 1)
      })
    },
    br2nl(str) {
      return str.replace(/<\s*\/?br\s*[\/]?>/gi, "\n")
    },

    doSubmit() {
      this.$refs['VFormImplementasi'].validate().then(success => {
        if (!success) return false

        this.$swal.fire({
          icon: "warning",
          title: "<h2 class='font-weight-bold'>Sudah Yakin Dengan Data yang Diisi?</h2>",
          html: `
            <span style="font-size: 16px">
              Pastikan untuk mengisikan data yang sesuai, karena data yang sudah disubmit sudah tidak dapat diubah
            </span>              
          `,

          showCancelButton: true,
          cancelButtonText: "Cek Ulang",
          confirmButtonText: "Lanjutkan & Submit"
        }).then(button => {
          if (button.isConfirmed) {
            this.$parent.doSubmitImplementasi({
              ...this.formData,
              type: 'submit-implementasi'
            })
          }
        })
      })
    }
  },

  computed: {
    formData(){
      return this.$parent.formData
    },
    mrValidation() {
      return this.$parent.mrValidation
    },

  },
}
</script>
